import { FC, PropsWithChildren, ReactNode } from 'react';
import styles from './PageContainer.module.css';

interface IPageContainerProps {
	pageHeader: ReactNode;
	withInnerPadding?: boolean;
}

const PageContainer: FC<PropsWithChildren<IPageContainerProps>> = ({ pageHeader, withInnerPadding, children }) => {
	return (
		<div className='flex flex-column h-100'>
			{pageHeader}

			<div className={`${withInnerPadding ? styles.inner_page_padding : ''} flex-fill overflow-scroll-y`}>
				{children}
			</div>
		</div>
	);
};

export default PageContainer;
