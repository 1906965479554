import { lazy } from 'react';
import { AreaProvider } from 'hooks/useArea';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS, SUPER_ADMIN_PERMISSION } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import AreaDetailsLayout from '../AreaDetailsLayout';
import { AREA_TABS_ROUTES } from './routes.area-tabs';
import { FlagOutlined } from '@ant-design/icons';

const AreasList = lazy(() => import('pages/Areas/AreasList'));
const AreaDetails = lazy(() => import('pages/Areas/AreaDetails'));
const AddArea = lazy(() => import('pages/Areas/AddArea'));

export const BLOCKING_AREAS_EDIT_BY_RICARDO = [SUPER_ADMIN_PERMISSION]; // todo @ricardo,

export const AREAS_ROUTES: TRouteObject = {
	path: 'areas',
	element: <ListParamsRouteWrapper key='areas' />,
	handle: {
		translationKey: 'areas',
		icon: <FlagOutlined />,
		showOnNav: true,
		permissions: [APP_PERMISSIONS.areas.view],
	},
	children: [
		{
			index: true,
			element: <AreasList />,
			handle: {
				defaultListParams: LIST_DEFAULT_PARAMS,
				showOnNav: false,
			},
		},
		{
			path: 'new',
			element: <AddArea />,
			handle: {
				translationKey: 'add_new_area',
				permissions: [...BLOCKING_AREAS_EDIT_BY_RICARDO, APP_PERMISSIONS.areas.add],
				showOnNav: false,
			},
		},
		{
			path: ':areaId',
			element: (
				<AreaProvider>
					<AreaDetailsLayout />
				</AreaProvider>
			),
			children: [
				{
					index: true,
					element: <AreaDetails />,
					handle: {
						translationKey: 'area_details',
						showOnNav: false,
					},
				},
				// Other tabs
				...AREA_TABS_ROUTES,
			],
		},
	],
};
