import { THttpRequestConfig } from 'hooks';
import { IAddRoleDataConfig, IAddRoleForm } from 'types/api';

export const ROLES_API = {
	list: (name?: string, order_by: string = 'desc'): THttpRequestConfig => ({
		url: 'operations/roles',
		method: 'GET',
		params: {
			name,
			order_by,
		},
	}),

	create: (data: IAddRoleDataConfig): THttpRequestConfig => ({
		url: 'operations/roles',
		method: 'POST',
		data,
	}),

	update: (roleId: string | number, data: IAddRoleForm): THttpRequestConfig => ({
		url: `operations/roles/${roleId}`,
		method: 'PATCH',
		data,
	}),

	createPermissions: (roleId: string | number, permission_ids: number[]): THttpRequestConfig => ({
		url: `operations/roles/${roleId}/permissions`,
		method: 'POST',
		data: {
			permission_ids,
		},
	}),

	listPermissions: (id: number | string): THttpRequestConfig => ({
		url: `operations/roles/${id}/permissions`,
		method: 'GET',
	}),
};
