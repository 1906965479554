import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { isNumber } from 'utils/validationUtils/isNumber';
import { CUSTOMER_SEGMENT_API } from 'configs/api';
import { ICreateUpdateCustomerSegmentPayload, ICustomer, ICustomerSegment } from 'types/api';
import { EStatus, TEmptyFunction } from 'types/common';
import { useNewHttpClient } from './useHttpClient';
import { App } from 'antd';

export interface ICustomerSegmentContextData extends ReturnType<typeof useCustomerSegmentContext> {}

export const useCustomerSegmentContext = () => {
	const { message } = App.useApp();
	const { customerSegmentId: paramId } = useParams();
	const customerSegmentId = paramId ?? '';

	const { t: tCustomerSegments } = useTranslation('campaign-customer-segment');

	// ! states
	const [data, setData] = useState<ICustomerSegment>();

	// ! http client
	const fetchHttpClient = useNewHttpClient<ICustomerSegment>();
	const updateHttpClient = useNewHttpClient<ICustomerSegment>();
	const toggleStateHttpClient = useNewHttpClient();
	const manageCustomerListHttpClient = useNewHttpClient();

	// ! handlers
	const fetchCustomerSegment = useCallback(() => {
		if (!customerSegmentId || !isNumber(+customerSegmentId)) return;

		fetchHttpClient.request({
			requestConfig: CUSTOMER_SEGMENT_API.get(+customerSegmentId),
			successCallback: (data) => {
				setData(data);
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerSegmentId]);

	const edit = (payload: ICreateUpdateCustomerSegmentPayload, successCb: TEmptyFunction) => {
		return updateHttpClient.request({
			requestConfig: CUSTOMER_SEGMENT_API.update(+customerSegmentId, payload),
			successCallback: () => {
				setData((prev) => (prev ? { ...prev, ...payload } : prev));

				// success message
				const msg = tCustomerSegments('messages.update.success_message', { id: customerSegmentId });

				message.success(msg, 3);

				successCb();
			},
		});
	};

	const toggleStatus = (newStatus: EStatus) => {
		return toggleStateHttpClient.request({
			requestConfig: CUSTOMER_SEGMENT_API.toggleState(+customerSegmentId, newStatus),
			successCallback: () => {
				setData((prev) => (prev ? { ...prev, status: newStatus } : prev));

				// success message
				const msg = tCustomerSegments('messages.toggle_status.success_message', { id: customerSegmentId });

				message.success(msg, 3);
			},
		});
	};

	const addCustomerList = (customerId: Array<ICustomer['id']>, successCallback: TEmptyFunction) => {
		return manageCustomerListHttpClient.request({
			requestConfig: CUSTOMER_SEGMENT_API.addCustomerList(+customerSegmentId, customerId),
			successCallback,
		});
	};

	const removeCustomer = (customerId: ICustomer['id'], successCallback: TEmptyFunction) => {
		return manageCustomerListHttpClient.request({
			requestConfig: CUSTOMER_SEGMENT_API.removeCustomer(+customerSegmentId, customerId),
			successCallback,
		});
	};

	// ! effects
	useEffect(() => {
		fetchCustomerSegment();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		customerSegmentId,
		data,
		edit,
		toggleStatus,
		addCustomerList,
		removeCustomer,
		manageCustomerListHttpClient,
		updateHttpClient,
		toggleStateHttpClient,
	};
};

const Context = createContext<ICustomerSegmentContextData>({} as ICustomerSegmentContextData);

export const useCustomerSegment = () => {
	return useContext(Context);
};

export const CustomerSegmentProvider = ({ children = <Outlet /> }: PropsWithChildren) => {
	const customerSegmentController = useCustomerSegmentContext();

	return <Context.Provider value={customerSegmentController}>{children}</Context.Provider>;
};
