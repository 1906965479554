import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	IDeliveryTimes,
	useAreaListController,
	useAuth,
	useBranch,
	useGoogleMapsApiKey,
	useNewHttpClient,
	useVendor,
} from 'hooks';
import { isNumber } from 'utils/validationUtils/isNumber';
import { VENDOR_BRANCH_DELIVERY_AREAS_API } from 'configs/api';
import { GLOBAL_MAP_CONTAINER_STYLE } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import { DEFAULT_POLYGON_OPTIONS, DIVIDER_STYLES } from './configs';
import {
	EStoreDeliveryAreaVehicleType,
	IArea,
	IVendorBranchDeliveryArea,
	IVendorBranchDeliveryAreasPatch,
} from 'types/api';
import { IListResponse, Nullable } from 'types/common';
import AreaCustomDrawer from 'components/Areas/AreaCustomDrawer';
import AreaInfoWindow from 'components/Areas/AreaInfoWindow';
import AreaPolygon from 'components/Areas/AreaPolygon';
import AreasMapHeader from 'components/Areas/AreasMapHeader';
import DeliveryAreaVehicleTypeMarker from 'components/Areas/DeliveryAreaVehicleTypeMarker';
import SelectedAreaList from 'components/Areas/SelectedAreaList';
import { polygonsIntersect } from 'components/Areas/helper';
import CustomDivider from 'components/CustomDivider';
import { ICustomDrawerHandle } from 'components/CustomDrawer';
import PolygonMap, { IPolygonMapHandle } from 'components/PolygonMap';
import Spinner from 'components/Spinner';
import { AreaVehicleTypeSelector } from './AreaVehicleTypeSelector';
import DeliveryFeeSection from './DeliveryFeeSection';
import DeliveryTimeSection from './DeliveryTimeSection';
import { SelectByPolygonHelper } from './SelectByPolygonHelper';
import { App, Flex } from 'antd';
import { Marker, Polygon } from '@react-google-maps/api';
import locationPinLogo from 'assets/images/location-pin.svg';

const DeliveryAreas: FC = () => {
	const { message } = App.useApp();
	const { hasPermission } = useAuth();
	const { mapApiKey } = useGoogleMapsApiKey();
	const { vendorId } = useVendor();
	const { branchId, data: branchData } = useBranch();

	const {
		areas,
		areasMap,
		//
		isViewAllAreas,
		setIsViewAllAreas,
		onViewAllAreasChange,
		//
		selectArea,
		selectedAreasMap,
		setSelectedAreasMap,
		replaceSelectedAreaList,
		appendNewSelectedAreas,
		//
		infoWindowAreaId,
		setInfoWindowAreaId,
		//
		hoveredAreaId,
		setHoveredAreaId,
		//
		hiddenAreasMap,
		clearHiddenAreas,
		unHideAreasOnPointer,
		toggleHiddenArea,
		handleHideArea,
		//
		updateAreaVehicleType,
		selectedVehicleTypeMap,
		setSelectedVehicleTypeMap,
		selectedDeliveryFeeMap,
		setSelectedDeliveryFeeMap,
		selectedDeliveryTimesMap,
		setSelectedDeliveryTimesMap,
	} = useAreaListController();

	const { t: tVendorBranchDeliveryArea } = useTranslation('vendors', {
		keyPrefix: 'vendor_details.tabs.branches.branch_details.tabs.delivery_area',
	});

	// ! refs
	const editToolsDrawerRef = useRef<ICustomDrawerHandle>(null);

	// ! httpClients
	const getHttpClient = useNewHttpClient<IListResponse<IVendorBranchDeliveryArea>>();
	const updateHttpClient = useNewHttpClient();

	// ! refs
	const mapRef = useRef<IPolygonMapHandle>(null);
	const polygonRef = useRef<Nullable<google.maps.Polygon>>(null);

	// ! states
	const [polygonPath, setPolygonPath] = useState<google.maps.LatLngLiteral[]>([]);
	const [isDrawingPolygonArea, setIsDrawingPolygonArea] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	// ! memos
	const canSaveDeliveryAreas = useMemo(
		() => hasPermission(APP_PERMISSIONS.vendor.store.delivery_area.save),
		[hasPermission]
	);

	const intersectedAreasByDrawnPolygon = useMemo(() => {
		return areas.reduce<Record<IArea['id'], boolean>>((acc, area) => {
			if (polygonsIntersect(polygonPath, area.polygon?.coordinates)) {
				acc[area.id] = true;
			}

			return acc;
		}, {});
	}, [areas, polygonPath]);

	const branchLocation = useMemo<google.maps.LatLngLiteral | undefined>(() => {
		if (!branchData) return undefined;

		const { location } = branchData;

		return { lat: +location.lat, lng: +location.lng };
	}, [branchData]);

	// ! helpers
	const getDeliveryAreas = () => {
		getHttpClient.request({
			requestConfig: VENDOR_BRANCH_DELIVERY_AREAS_API.getDeliveryAreas(vendorId, branchId),
			successCallback: ({ data }) => {
				const newSelectedAreasMap: Record<IArea['id'], boolean> = {};
				const newSelectedVehicleTypeMap: Record<IArea['id'], EStoreDeliveryAreaVehicleType> = {};
				const newSelectedDeliveryFeeMap: Record<IArea['id'], IVendorBranchDeliveryArea['delivery_fee']> = {};
				const newSelectedDeliveryTimesMap: Record<IArea['id'], IDeliveryTimes> = {};

				data.forEach(
					({ area_id, vehicle_type, delivery_fee, min_order_delivery_time, max_order_delivery_time }) => {
						newSelectedAreasMap[area_id] = true;
						newSelectedVehicleTypeMap[area_id] = vehicle_type;
						newSelectedDeliveryFeeMap[area_id] = delivery_fee;
						newSelectedDeliveryTimesMap[area_id] = {
							min: min_order_delivery_time,
							max: max_order_delivery_time,
						};
					}
				);

				setSelectedDeliveryTimesMap(newSelectedDeliveryTimesMap);
				setSelectedDeliveryFeeMap(newSelectedDeliveryFeeMap);
				setSelectedVehicleTypeMap(newSelectedVehicleTypeMap);
				setSelectedAreasMap(newSelectedAreasMap);
			},
		});
	};

	// ! handlers
	const onSubmitNewAreas = () => {
		const payloadSelectedAreas = { ...selectedAreasMap };
		const selectedAreaListIds: IVendorBranchDeliveryAreasPatch['areas'] = Object.entries(payloadSelectedAreas)
			.filter(([id, isSelected]) => isNumber(+id) && isSelected)
			.map(([id]) => ({
				area_id: +id,
				vehicle_type: selectedVehicleTypeMap[+id],
				delivery_fee: selectedDeliveryFeeMap[+id],
				min_order_delivery_time: selectedDeliveryTimesMap[+id].min,
				max_order_delivery_time: selectedDeliveryTimesMap[+id].max,
			}));

		updateHttpClient.request({
			requestConfig: VENDOR_BRANCH_DELIVERY_AREAS_API.updateDeliveryAreas(vendorId, branchId, {
				areas: selectedAreaListIds,
			}),
			successCallback: () => {
				message.success(tVendorBranchDeliveryArea('messages.update.success'), 3);

				handleCleanup();

				setSelectedAreasMap(payloadSelectedAreas);
			},
		});
	};

	const onSubmitDrawSelection = () => {
		const selectedAreas = Object.keys(intersectedAreasByDrawnPolygon).map((id) => +id);
		appendNewSelectedAreas(selectedAreas);

		onCancelDrawSelection();
	};

	const handleCleanup = () => {
		setPolygonPath([]);
		polygonRef.current?.setPath([]);
		setIsDrawingPolygonArea(false);
		setIsEditing(false);
		editToolsDrawerRef.current?.close();

		setIsViewAllAreas(true);
		clearHiddenAreas();
		setSelectedAreasMap({});
	};

	const onCancelDrawSelection = () => {
		setPolygonPath([]);
		polygonRef.current?.setPath([]);
		setIsDrawingPolygonArea(false);
	};

	const onToggleDrawerOpen = () => editToolsDrawerRef.current?.toggle();

	// * Map CRUD
	const onMapClick = (event: google.maps.MapMouseEvent) => {
		if (!isDrawingPolygonArea) return;

		const newPolygonPosition = event.latLng?.toJSON();
		if (!newPolygonPosition) return;
		setPolygonPath((prev) => [...prev, newPolygonPosition]);
	};

	// * Drawing handles
	const onStartDrawSelection = () => setIsDrawingPolygonArea(true);

	const getEditingPolygonPath = useCallback(
		(polygonRef: Nullable<google.maps.Polygon>): google.maps.LatLngLiteral[] => {
			if (!polygonRef) return [];

			const path = polygonRef.getPath();

			const polygonPathArray = path.getArray();
			if (!polygonPathArray?.length) return [];

			const newPath = polygonPathArray.map((latLng) => latLng.toJSON());

			return newPath;
		},
		[]
	);

	const onDrawnPolygonChange = useCallback(() => {
		setPolygonPath(getEditingPolygonPath(polygonRef.current));
	}, [getEditingPolygonPath]);

	const onPolygonLoad = useCallback((polyRef: google.maps.Polygon) => (polygonRef.current = polyRef), []);

	const onPolygonUnmount = useCallback(() => (polygonRef.current = null), []);

	// ! effects
	useEffect(() => {
		getDeliveryAreas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [branchId, vendorId]);

	useEffect(() => {
		mapRef.current?.getMapInstance()?.setOptions({
			disableDoubleClickZoom: isEditing,
		});
	}, [isEditing]);

	// ! render
	return (
		<div className='h-100 flex flex-column'>
			{/* LOADING STATE */}
			{getHttpClient.isLoading && <Spinner defaultAntdSpinner />}

			{/* HEADER */}
			<AreasMapHeader
				isEditingMap={isEditing}
				loading={getHttpClient.isLoading}
				toolsButtonDisabled={!canSaveDeliveryAreas}
				areasCount={Object.entries(selectedAreasMap).length}
				onToolsButtonClick={() => {
					onToggleDrawerOpen();
					setIsEditing(true);
				}}
			/>

			{/* MAP */}
			<div className='flex-fill'>
				{mapApiKey && (
					<PolygonMap
						ref={mapRef}
						zoom={11}
						mapCenter={branchLocation}
						googleMapsApiKey={mapApiKey}
						mapContainerStyle={GLOBAL_MAP_CONTAINER_STYLE}
						onClick={onMapClick}
						onRightClick={(e) => {
							const clickCoords = e.latLng;
							if (!clickCoords) return;
							unHideAreasOnPointer(clickCoords);
						}}
					>
						{branchLocation && (
							<Marker
								title={tVendorBranchDeliveryArea('branch_location')}
								icon={locationPinLogo}
								position={branchLocation}
							/>
						)}

						{/* POLYGON Draw AREA */}
						<Polygon
							path={polygonPath}
							options={DEFAULT_POLYGON_OPTIONS}
							editable={isDrawingPolygonArea}
							draggable={isDrawingPolygonArea}
							onLoad={onPolygonLoad}
							onMouseUp={onDrawnPolygonChange}
							onDragEnd={onDrawnPolygonChange}
							onUnmount={onPolygonUnmount}
						/>

						{areas.map((area) => (
							<div key={area.id}>
								<AreaPolygon
									area={area}
									clickable={!isDrawingPolygonArea}
									isHovered={hoveredAreaId === area.id}
									isSelected={selectedAreasMap[area.id]}
									isIntersected={intersectedAreasByDrawnPolygon[area.id]}
									isVisible={
										(selectedAreasMap[area.id] || isViewAllAreas) && !hiddenAreasMap[area.id]
									}
									onRightClick={() =>
										isEditing && !isDrawingPolygonArea ? selectArea(area.id) : undefined
									}
									onClick={() => setInfoWindowAreaId(area.id)}
									onMouseOver={() => setHoveredAreaId(area.id)}
								/>

								<DeliveryAreaVehicleTypeMarker
									visible={
										!isDrawingPolygonArea &&
										(selectedAreasMap[area.id] || isViewAllAreas) &&
										!hiddenAreasMap[area.id]
									}
									isSelected={selectedAreasMap[area.id]}
									vehicleType={selectedVehicleTypeMap[area.id]}
									areaDeliveryFee={selectedDeliveryFeeMap[area.id]}
									areaDeliveryTimes={selectedDeliveryTimesMap[area.id]}
									polygonPath={area.polygon?.coordinates}
									areaCenter={area.center}
								/>
							</div>
						))}

						{infoWindowAreaId && (
							<AreaInfoWindow
								area={areasMap.current[infoWindowAreaId]}
								isEditing={isEditing}
								isSelected={selectedAreasMap[infoWindowAreaId]}
								onCloseClick={() => setInfoWindowAreaId(null)}
								onHideArea={() => handleHideArea(infoWindowAreaId)}
								onSelectArea={() => selectArea(infoWindowAreaId)}
								extra={
									selectedAreasMap[infoWindowAreaId] && (
										<>
											<CustomDivider style={DIVIDER_STYLES} />

											<AreaVehicleTypeSelector
												disabled={!isEditing}
												selectedVehicleType={selectedVehicleTypeMap[infoWindowAreaId]}
												onChange={(type) => updateAreaVehicleType(infoWindowAreaId, type)}
											/>

											<CustomDivider style={DIVIDER_STYLES} />

											<DeliveryFeeSection
												disabled={!isEditing}
												areaId={infoWindowAreaId}
												selectedFee={selectedDeliveryFeeMap[infoWindowAreaId]}
												setSelectedDeliveryFeeMap={setSelectedDeliveryFeeMap}
											/>

											<CustomDivider style={DIVIDER_STYLES} />

											<DeliveryTimeSection
												disabled={!isEditing}
												areaId={infoWindowAreaId}
												setSelectedDeliveryTimesMap={setSelectedDeliveryTimesMap}
												selectedTime={selectedDeliveryTimesMap[infoWindowAreaId]}
											/>
										</>
									)
								}
							/>
						)}

						<AreaCustomDrawer
							ref={editToolsDrawerRef}
							showMainActions={!isDrawingPolygonArea}
							submitLoading={updateHttpClient.isLoading}
							title={tVendorBranchDeliveryArea('area_drawer_title')}
							onClose={onToggleDrawerOpen}
							onSubmitNewAreas={onSubmitNewAreas}
							onRevertChanges={() => {
								handleCleanup();
								getDeliveryAreas();
							}}
						>
							<Flex
								vertical
								gap={20}
								className='h-100'
							>
								<SelectByPolygonHelper
									setPolygonPath={(newPath) => {
										polygonRef.current?.setPath(newPath);
										setPolygonPath(newPath);
									}}
									isDefiningPolygonArea={isDrawingPolygonArea}
									areaList={Object.keys(intersectedAreasByDrawnPolygon).map(
										(areaId) => areasMap.current[+areaId]
									)}
									onCreateOrEditPolygonArea={onStartDrawSelection}
									onCancelAreaListSelection={onCancelDrawSelection}
									onSubmitPreSelection={onSubmitDrawSelection}
								/>

								{!isDrawingPolygonArea && (
									<div className='flex-fill'>
										<SelectedAreaList
											areas={areas}
											hiddenAreas={hiddenAreasMap}
											selectedAreas={selectedAreasMap}
											isViewAllAreas={isViewAllAreas}
											selectArea={selectArea}
											replaceSelectedAreaList={replaceSelectedAreaList}
											setHoveredAreaId={setHoveredAreaId}
											toggleHiddenArea={toggleHiddenArea}
											handleViewAllArea={onViewAllAreasChange}
										/>
									</div>
								)}
							</Flex>
						</AreaCustomDrawer>
					</PolygonMap>
				)}
			</div>
		</div>
	);
};

export default DeliveryAreas;
