import { pipe, traverseRouteTree } from 'utils/processRoutes';
import { INavMenuRoute, TRouteObject } from 'configs/routes';

const transformRouteObjectToNavItem = (route: TRouteObject, parentRoute?: string): INavMenuRoute => {
	const newRoutePath = (parentRoute ? `${parentRoute}/` : '') + route.path;

	const newRoute: INavMenuRoute = {
		path: route.path,
		translationKey: route.handle?.translationKey,
		permissions: route.handle?.permissions,
		icon: route.handle?.icon,
		showOnNav: route.handle?.showOnNav ?? false,
		isNavigateable: route.handle?.isNavigateable,
		defaultListParams: route.handle?.defaultListParams,
	};

	if (route.index) {
		newRoute.path = parentRoute + '/';
	} else {
		newRoute.path = newRoutePath;
	}

	return newRoute;
};

export const getNavItemsRecursive = (routes: TRouteObject[], parentRoute?: string): INavMenuRoute[] => {
	const result = routes.map((route) => {
		const routeNavItem = transformRouteObjectToNavItem(route, parentRoute);

		routeNavItem.children = getNavItemsRecursive(route.children ?? [], routeNavItem.path);

		if (!routeNavItem.children.length) {
			routeNavItem.children = undefined;
		}

		return routeNavItem;
	});

	return result;
};

const filterRouteByShowOnNav = (route: TRouteObject) => (route?.handle?.showOnNav ? route : undefined);

const filterRouteChildlessRoutes = (route: TRouteObject) =>
	route.element || route.children?.length ? route : undefined;

// transform routes to nav items structure
export const getNavItems = (routes: TRouteObject[]): INavMenuRoute[] => {
	return getNavItemsRecursive(
		traverseRouteTree(
			routes,
			pipe(
				//
				filterRouteByShowOnNav,
				filterRouteChildlessRoutes
			)
		)
	);
};
