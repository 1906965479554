import { IPermissionItem } from 'types/api';

export const APP_PERMISSIONS = {
	dashboard: {
		view: {
			code: 'dashboard.view',
			description: 'View And Access Dashboard',
		},
	},
	citc: {
		view: {
			code: 'citc.view',
			description: 'Ability to view CITC details',
		},
		edit: {
			code: 'citc.edit',
			description: 'Ability to edit CITC',
		},
	},
	operations_user: {
		view: {
			code: 'operations_user.view',
			description: 'View And Access Operations Users',
		},
		add: {
			code: 'operations_user.add',
			description: 'Add New Operations User',
		},
		update: {
			code: 'operations_user.update',
			description: 'Update Operations User',
		},
		delete: {
			code: 'operations_user.delete',
			description: 'Delete Operations User',
		},
		update_roles_and_permissions: {
			code: 'operations_user.update_roles_and_permissions',
			description: 'Update Roles/Permissions Of Operations User',
		},
	},
	promotion: {
		view: {
			code: 'promotion.view',
			description: 'View And Access Promotions',
		},
		add: {
			code: 'promotion.add',
			description: 'Add New Promotion',
		},
		update: {
			code: 'promotion.update',
			description: 'Update Promotion',
		},
	},
	cari_prize_campaign: {
		view: {
			code: 'cari_prize_campaign.view',
			description: 'View And Access Cari Prize campaign',
		},
		add: {
			code: 'cari_prize_campaign.add',
			description: 'Add New Cari Prize campaign',
		},
		update: {
			code: 'cari_prize_campaign.update',
			description: 'Update Cari Prize campaign',
		},
	},
	category: {
		view: {
			code: 'category.view',
			description: 'View And Access Categories',
		},
		add: {
			code: 'category.add',
			description: 'Add New Category',
		},
		update: {
			code: 'category.update',
			description: 'Update Category',
		},
		delete: {
			code: 'category.delete',
			description: 'Delete Category',
		},
	},
	company: {
		view: {
			code: 'company.view',
			description: 'View And Access Companies',
		},
		add: {
			code: 'company.add',
			description: 'Add New Company',
		},
		update: {
			code: 'company.update',
			description: 'Update Company',
		},
		delete: {
			code: 'company.delete',
			description: 'Delete Company',
		},
	},
	menu: {
		add: {
			code: 'menu.add',
			description: 'Add New Menu',
		},
		view: {
			code: 'menu.view',
			description: 'View And Access Menu',
		},
		update: {
			code: 'menu.update',
			description: 'Update Menu',
		},
		delete: {
			code: 'menu.delete',
			description: 'Delete Menu',
		},
	},
	dispatcher: {
		view: {
			code: 'dispatcher.view',
			description: 'View And Access Dispatcher',
		},
		assign_driver: {
			code: 'dispatcher.assign_driver',
			description: 'Ability To Assign Driver To An Order',
		},
		unassign_driver: {
			code: 'dispatcher.unassign_driver',
			description: 'Ability To Unassign Driver From An Order',
		},
	},
	general_setting: {
		view: {
			code: 'general_setting.view',
			description: 'View And Access General Setting Entries',
		},
		update: {
			code: 'general_setting.update',
			description: 'Update General Setting Entries',
		},
	},
	live_tracking: {
		view: {
			code: 'live_tracking.view',
			description: "View And Access Driver's Live Tracking Page",
		},
	},
	order: {
		view: {
			code: 'order.view',
			description: 'View And Access Orders',
		},
		update: {
			code: 'order.update',
			description: 'Update Order',
		},
		mark_store_settled: {
			code: 'order.mark_store_settled',
			description: 'Ability To Settle Store Payment Status For An Order',
		},
		mark_driver_settled: {
			code: 'order.mark_driver_settled',
			description: 'Ability To Settle Driver Payment Status For An Order',
		},
		download_order_invoice: {
			code: 'order.download_order_invoice',
			description: 'Ability To Download Order Invoice',
		},
		view_timeline: {
			code: 'order.view_timeline',
			description: 'View And Access Order History/Timeline',
		},
		view_payment_response_from_gateway: {
			code: 'order.view_payment_response_from_gateway',
			description: 'View The Payment Response Received From The Payment Gateway For Order',
		},
		order_status_manual_correction: {
			code: 'order.order_status_manual_correction',
			description: 'Ability to correct the overall status of an order',
		},
		rebroadcast_drivers: {
			code: 'order.rebroadcast_drivers',
			description: 'Ability to re-brodcast hanging orders',
		},
		create_correction_order: {
			code: 'order.create_correction_order',
			description: 'Ability to create a correction order',
		},
	},
	payment_type: {
		view: {
			code: 'payment_type.view',
			description: 'View And Access Payment Types',
		},
		manage: {
			code: 'payment_type.manage',
			description: 'Ability To Manage the Payment Types',
		},
	},
	profile: {
		update: {
			code: 'profile.update',
			description: 'Ability To Update Own Profile Details',
		},
		reset_password: {
			code: 'profile.reset_password',
			description: 'Ability To Reset Own Account Password',
		},
	},
	push_notification: {
		view: {
			code: 'push_notification.view',
			description: 'View And Access Sent Push Notifications',
		},
		send: {
			code: 'push_notification.send',
			description: 'Send Push Notification',
		},
	},
	driver_rating: {
		view: {
			code: 'driver_rating.view',
			description: 'View And Access Driver Ratings',
		},
		update_visibility_status: {
			code: 'driver_rating.update_visibility_status',
			description: 'Ability To Hide/Show Driver Ratings',
		},
	},
	store_rating: {
		view: {
			code: 'store_rating.view',
			description: 'View And Access Branch Ratings',
		},
		update_visibility_status: {
			code: 'store_rating.update_visibility_status',
			description: 'Ability To Hide/Show Branch Ratings',
		},
	},
	refund: {
		view: {
			code: 'refund.view',
			description: 'View And Access Issued Refunds',
		},
		update: {
			code: 'refund.update',
			description: 'Update Issued Refunds',
		},
	},
	required_document: {
		view: {
			code: 'required_document.view',
			description: 'View And Access System Required Documents',
		},
		add: {
			code: 'required_document.add',
			description: 'Add New System Required Document Entry',
		},
		update: {
			code: 'required_document.update',
			description: 'Update System Required Document Entry',
		},
		delete: {
			code: 'required_document.delete',
			description: 'Delete System Required Document Entry',
		},
	},
	role: {
		view: {
			code: 'role.view',
			description: 'View And Access Roles',
		},
		add: {
			code: 'role.add',
			description: 'Add New Role',
		},
		update: {
			code: 'role.update',
			description: 'Update Role',
		},
		update_permission: {
			code: 'role.update_permission',
			description: 'Update Permissions Of Role',
		},
	},
	supplier: {
		view: {
			code: 'supplier.view',
			description: 'View And Access Suppliers',
		},
		add: {
			code: 'supplier.add',
			description: 'Add New Supplier',
		},
		update: {
			code: 'supplier.update',
			description: 'Update Supplier',
		},
		delete: {
			code: 'supplier.delete',
			description: 'Delete Supplier',
		},
	},
	support_page: {
		view: {
			code: 'support_page.view',
			description: 'View And Access Support Pages',
		},
		add: {
			code: 'support_page.add',
			description: 'Create New Support Page',
		},
		update: {
			code: 'support_page.update',
			description: 'Update Support Page',
		},
		delete: {
			code: 'support_page.delete',
			description: 'Delete Support Page',
		},
	},
	customer: {
		view: {
			code: 'customer.view',
			description: 'View And Access Customers',
		},
		update: {
			code: 'customer.update',
			description: 'Update Customer',
		},
		delete: {
			code: 'customer.delete',
			description: 'Delete Customer',
		},
		restore: {
			code: 'customer.restore',
			description: 'Restore Customer',
		},
		address: {
			view: {
				code: 'customer.address.view',
				description: "View And Access Customer's Addresses",
			},
			add: {
				code: 'customer.address.add',
				description: 'Create Customer Address',
			},
			update: {
				code: 'customer.address.update',
				description: 'Update Customer Address',
			},
			delete: {
				code: 'customer.address.delete',
				description: 'Delete Customer Address',
			},
		},
		order: {
			issue_order_refund: {
				code: 'customer.order.issue_order_refund',
				description: 'Ability To Issue Refund For Customer Orders',
			},
		},
		wallet: {
			view_history: {
				code: 'customer.wallet.view_history',
				description: 'View And Access Customer Wallet Transactions',
			},
			credit: {
				code: 'customer.wallet.credit',
				description: 'Ability To Credit Funds From Customer Wallet',
			},
			debit: {
				code: 'customer.wallet.debit',
				description: 'Ability To Debit Funds Into Customer Wallet',
			},
		},
	},
	driver: {
		view: {
			code: 'driver.view',
			description: 'View And Access Drivers',
		},
		update: {
			code: 'driver.update',
			description: 'Update Driver',
		},
		update_status: {
			code: 'driver.update_status',
			description: 'Update Driver Status',
		},
		delete: {
			code: 'driver.delete',
			description: 'Delete Driver',
		},
		reset_password: {
			code: 'driver.reset_password',
			description: 'Ability To Reset Driver Password',
		},
		clear_ongoing_orders: {
			code: 'driver.clear_ongoing_orders',
			description: 'Ability To Clear Current Ongoing Orders Of Driver',
		},
		vehicle_details: {
			view: {
				code: 'driver.vehicle_details.view',
				description: 'View And Access Driver Vehicle Details',
			},
			update: {
				code: 'driver.vehicle_details.update',
				description: 'Update Driver Vehicle Details',
			},
		},
		document: {
			view: {
				code: 'driver.document.view',
				description: 'View And Access Driver Documents',
			},
			update_status: {
				code: 'driver.document.update_status',
				description: 'Ability To Approve/Reject Driver Document',
			},
			delete: {
				code: 'driver.document.delete',
				description: 'Delete Driver Document',
			},
		},
		bank_info: {
			view: {
				code: 'driver.bank_info.view',
				description: 'View And Access Driver Bank Details',
			},
			update: {
				code: 'driver.bank_info.update',
				description: 'Update Driver Bank Details',
			},
		},
		logs: {
			view: {
				code: 'driver.logs.view',
				description: 'View And Access Driver Logs',
			},
		},
	},
	vendor_user: {
		view: {
			code: 'vendor_user.view',
			description: 'View And Access Vendor Users',
		},
		add: {
			code: 'vendor_user.add',
			description: 'Add New Vendor User',
		},
		update: {
			code: 'vendor_user.update',
			description: 'Update Vendor User',
		},
		delete: {
			code: 'vendor_user.delete',
			description: 'Delete Vendor User',
		},
	},
	vendor: {
		view: {
			code: 'vendor.view',
			description: 'View And Access Vendors',
		},
		add: {
			code: 'vendor.add',
			description: 'Add New Vendor',
		},
		update: {
			code: 'vendor.update',
			description: 'Update Vendor',
		},
		delete: {
			code: 'vendor.delete',
			description: 'Delete Vendor',
		},
		upload_image: {
			code: 'vendor.upload_image',
			description: 'Ability To Upload Vendor Image',
		},
		upload_banner_image: {
			code: 'vendor.upload_banner_image',
			description: 'Ability To Upload Vendor Banner Image',
		},
		update_orders_in_progress_limit: {
			code: 'vendor.update_orders_in_progress_limit',
			description: 'Update Number Of In Progress Orders That A Store Can Handle At A Time',
		},
		integration_migration: {
			code: 'vendor.integration_migration',
			description: 'Migrate a vendor to integration type or revert it to platform',
		},
		category: {
			view: {
				code: 'vendor.category.view',
				description: 'View And Access Vendor Categories',
			},
			add: {
				code: 'vendor.category.add',
				description: 'Add New Vendor Category',
			},
			update: {
				code: 'vendor.category.update',
				description: 'Update Vendor Category',
			},
			delete: {
				code: 'vendor.category.delete',
				description: 'Delete Vendor Category',
			},
			upload_image: {
				code: 'vendor.category.upload_image',
				description: 'Ability To Upload Vendor Category Image',
			},
		},
		product: {
			view: {
				code: 'vendor.product.view',
				description: 'View And Access Vendor Products',
			},
			add: {
				code: 'vendor.product.add',
				description: 'Add New Vendor Product',
			},
			update: {
				code: 'vendor.product.update',
				description: 'Update Vendor Product',
			},
			delete: {
				code: 'vendor.product.delete',
				description: 'Delete Vendor Product',
			},
			upload_image: {
				code: 'vendor.product.upload_image',
				description: 'Ability To Upload Vendor Product Image',
			},
			bulk_upload: {
				code: 'vendor.product.bulk_upload',
				description: 'Ability To Bulk Upload Vendor Products And Their Children',
			},
			bulk_update: {
				code: 'vendor.product.bulk_update',
				description: 'Ability To Bulk Update Vendor Products And Their Children',
			},
		},
		menu: {
			view: {
				code: 'vendor.menu.view',
				description: 'View And Access Vendor Menus',
			},
			add: {
				code: 'vendor.menu.add',
				description: 'Add New Vendor Menu',
			},
			update: {
				code: 'vendor.menu.update',
				description: 'Update Vendor Menu',
			},
			delete: {
				code: 'vendor.menu.delete',
				description: 'Delete Vendor Menu',
			},
			sort: {
				code: 'vendor.menu.sort',
				description: 'Sort Vendor Menu',
			},
		},
		shift: {
			view: {
				code: 'vendor.shift.view',
				description: 'View And Access Vendor Shifts',
			},
			add: {
				code: 'vendor.shift.add',
				description: 'Add New Vendor Shift',
			},
			update: {
				code: 'vendor.shift.update',
				description: 'Update Vendor Shift',
			},
			delete: {
				code: 'vendor.shift.delete',
				description: 'Delete Vendor Shift',
			},
		},
		invoices: {
			view: {
				code: 'vendor.invoices.view',
				description: 'View And Access Vendor Invoices',
			},
		},
		store: {
			view: {
				code: 'vendor.store.view',
				description: 'View And Access Vendor Branches',
			},
			add: {
				code: 'vendor.store.add',
				description: 'Add New Vendor Branch',
			},
			update: {
				code: 'vendor.store.update',
				description: 'Update Vendor Branch',
			},
			delete: {
				code: 'vendor.store.delete',
				description: 'Delete Vendor Branch',
			},
			bulk_update_current_status: {
				code: 'vendor.store.bulk_update_current_status',
				description: 'Ability To Bulk Update Vendor Branch Current Status (Online/Offline)',
			},
			upload_image: {
				code: 'vendor.store.upload_image',
				description: 'Ability To Upload Vendor Branch Image',
			},
			upload_banner_image: {
				code: 'vendor.store.upload_banner_image',
				description: 'Ability To Update Vendor Branch Banner Image',
			},
			delivery_area: {
				view: {
					code: 'vendor.store.delivery_area.view',
					description: 'View And Access Vendor Branch Delivery Area',
				},
				save: {
					code: 'vendor.store.delivery_area.save',
					description: 'Ability to Update Vendor Branch Delivery Area',
				},
			},
			presence: {
				view: {
					code: 'vendor.store.presence.view',
					description: 'View And Access Vendor Branch Presence',
				},
				update: {
					code: 'vendor.store.presence.update',
					description: 'Ability to Update Vendor Branch Presence',
				},
			},
			product: {
				snooze: {
					code: 'vendor.store.product.snooze',
					description: 'Ability to snooze the product of a branch',
				},
			},
		},
		vendor_user: {
			view: {
				code: 'vendor.vendor_user.view',
				description: 'View And Access Vendor Staff Users',
			},
			add: {
				code: 'vendor.vendor_user.add',
				description: 'Add New Vendor Staff User',
			},
			update: {
				code: 'vendor.vendor_user.update',
				description: 'Update Vendor Staff User',
			},
			delete: {
				code: 'vendor.vendor_user.delete',
				description: 'Delete Vendor Staff User',
			},
			update_password: {
				code: 'vendor.vendor_user.update_password',
				description: 'Ability to Update Vendor Staff User Password',
			},
			associate_with_vendor: {
				code: 'vendor.vendor_user.associate_with_vendor',
				description: 'Ability to associate Vendor User with Vendor',
			},
			dissociate_from_vendor: {
				code: 'vendor.vendor_user.dissociate_from_vendor',
				description: 'Ability to dissociate Vendor User from Vendor',
			},
		},
		document: {
			view: {
				code: 'vendor.document.view',
				description: 'View And Access Vendor Documents',
			},
			upload: {
				code: 'vendor.document.upload',
				description: 'Upload New Vendor Document',
			},
			update_status: {
				code: 'vendor.document.update',
				description: 'Update Vendor Document Status',
			},
			delete: {
				code: 'vendor.document.delete',
				description: 'Delete Vendor Document',
			},
		},
		bank_info: {
			view: {
				code: 'vendor.bank_info.view',
				description: 'View And Access Vendor Bank Information',
			},
			save: {
				code: 'vendor.bank_info.save',
				description: 'Add/Update Vendor Bank Information',
			},
		},
		deep_link: {
			view: {
				code: 'vendor.deep_link.view',
				description: 'View And Access Vendor Deep Links',
			},
			save: {
				code: 'vendor.deep_link.save',
				description: 'Add/Update Vendor Deep Link',
			},
			delete: {
				code: 'vendor.deep_link.delete',
				description: 'Delete Vendor Deep Link',
			},
		},
		presence: {
			view: {
				code: 'vendor.presence.view',
				description: 'View And Access Vendor Presence',
			},
			update: {
				code: 'vendor.presence.update',
				description: 'Ability to Update Vendor Presence',
			},
		},
	},
	vertical: {
		view: {
			code: 'vertical.view',
			description: 'View And Access Verticals',
		},
		add: {
			code: 'vertical.add',
			description: 'Add New Vertical',
		},
		delete: {
			code: 'vertical.delete',
			description: 'Delete Vertical',
		},
		update: {
			code: 'vertical.update',
			description: 'Update Vertical',
		},
		upload_image: {
			code: 'vertical.upload_image',
			description: 'Ability To Upload Image For Verticals',
		},
		manage_vendor: {
			code: 'vertical.manage_vendor',
			description: 'Ability To Manage Vendors For Vertical',
		},
		areas: {
			view: {
				code: 'vertical.areas.view',
				description: 'View And Access Areas associated with a Vertical',
			},
		},
	},
	logistics: {
		view: {
			code: 'logistics.view',
			description: 'Access the logistics page',
		},
		bulk_update_eta: {
			code: 'logistics.bulk_update_eta',
			description: 'Ability to bulk update the ETA for all stores',
		},
		bulk_update_delivery_fee: {
			code: 'logistics.bulk_update_delivery_fee',
			description: 'Ability to bulk update the delivery fee for all stores delivered by the company',
		},
		bulk_update_stores_ranking: {
			code: 'logistics.bulk_update_stores_ranking',
			description: 'logistics.bulk_update_stores_ranking',
		},
		get_stores_ranking: {
			code: 'logistics.get_stores_ranking',
			description: 'logistics.get_stores_ranking',
		},
		add_stores_ranking: {
			code: 'logistics.add_stores_ranking',
			description: 'logistics.add_stores_ranking',
		},
		reset_ranking_list: {
			code: 'logistics.reset_ranking_list',
			description: 'logistics.reset_ranking_list',
		},
	},
	banners: {
		add: {
			code: 'banner.add',
			description: 'Ability to add banner',
		},
		view: {
			code: 'banner.view',
			description: 'Ability to view banner',
		},
		update: {
			code: 'banner.update',
			description: 'Ability to update banner',
		},
		activate: {
			code: 'banner.activate',
			description: 'Ability to activate banner',
		},
		deactivate: {
			code: 'banner.deactivate',
			description: 'Ability to deactivate banner',
		},
		delete: {
			code: 'banner.delete',
			description: 'Ability to delete banner',
		},
	},

	areas: {
		add: {
			code: 'area.add',
			description: 'Add New Area',
		},
		view: {
			code: 'area.view',
			description: 'View and Access Area',
		},
		update: {
			code: 'area.update',
			description: 'Update area',
		},
		delete: {
			code: 'area.delete',
			description: 'Delete area',
		},
		add_polygon: {
			code: 'area.polygon',
			description: 'Add a polygon to an area',
		},
		update_polygon: {
			code: 'area.update.polygon',
			description: 'Update the polygon of an area',
		},
		delete_polygon: {
			code: 'area.delete.polygon',
			description: 'Delete the polygon of an area',
		},
		suspend_area_orders: {
			code: 'area.suspend_area_orders',
			description: 'Ability to suspend orders in a specific area',
		},
		resume_area_orders: {
			code: 'area.resume_area_orders',
			description: 'Ability to resume orders in a specific area',
		},
	},
	store_cluster: {
		add: {
			code: 'store_cluster.add',
			description: 'Add New Store Cluster',
		},
		update: {
			code: 'store_cluster.update',
			description: 'Update Store Cluster',
		},
		delete: {
			code: 'store_cluster.delete',
			description: 'Delete Store Cluster',
		},
	},
	cari_unlimited: {
		view: {
			code: 'cari_unlimited.view',
			description: 'Ability To View Cari Unlimited',
		},
		manage: {
			code: 'cari_unlimited.manage',
			description: 'Ability To Manage CARI Unlimited',
		},
		subscription: {
			view: {
				code: 'cari_unlimited.subscription.view',
				description: 'Ability To View Cari Unlimited Subscription',
			},
			manage: {
				code: 'cari_unlimited.subscription.manage',
				description: 'Ability To Manage CARI Unlimited Subscription',
			},
		},
	},
	customer_segment: {
		add: {
			code: 'customer_segment.add',
			description: 'Add New Customer Segment',
		},
		view: {
			code: 'customer_segment.view',
			description: 'View and Access Customer Segment',
		},
		update: {
			code: 'customer_segment.update',
			description: 'Update Customer Segment',
		},
		delete: {
			code: 'customer_segment.delete',
			description: 'Delete Customer Segment',
		},
	},
	bin_segment: {
		manage: {
			code: 'bin_segment.manage',
			description: 'Ability To Access And Manage BIN Card Segments',
		},
	},
	offer_label: {
		view: {
			code: 'offer_label.view',
			description: 'View And Access Offers Labels',
		},
		add: {
			code: 'offer_label.add',
			description: 'Add New Offer Label',
		},
		update: {
			code: 'offer_label.update',
			description: 'Update Offer Label',
		},
		update_status: {
			code: 'offer_label.update_status',
			description: 'Update Offer Label Status',
		},
		delete: {
			code: 'offer_label.delete',
			description: 'Delete Offer Label',
		},
	},
	customer_support_requests: {
		view: {
			code: 'customer_support_request.view',
			description: 'View And Access Customer Support Requests',
		},
		update: {
			code: 'customer_support_request.update',
			description: 'Update Customer Support Request',
		},
	},
	customer_support_flow: {
		view: {
			code: 'customer_support_help_option.view',
			description: 'View And Access Customer Support Flow',
		},
		manage: {
			code: 'customer_support_help_option.update',
			description: 'Update Customer Support Flow',
		},
	},
	order_journey: {
		view: {
			code: 'order_journey.view',
			description: 'View And Access Order Journeys',
		},
	},
};

export const SUPER_ADMIN_PERMISSION: IPermissionConfigItem = {
	description: 'Super Admin Only',
	code: 'not_existing_route_permission_to_prevent_access_when_checking',
};

export interface IPermissionConfigItem {
	code: IPermissionItem['code'];
	description: IPermissionItem['description'];
}
