import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IMoreActionsProps } from './types';
import { Button, Dropdown, Tooltip } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

const MoreActions: FC<IMoreActionsProps> = ({ menu }) => {
	const { t: tCommon } = useTranslation('common');

	// ! render
	return (
		<Dropdown
			menu={menu}
			trigger={['click']}
			placement='bottomRight'
		>
			<Tooltip
				placement='topRight'
				title={tCommon('action_buttons.more_actions')}
			>
				<Button
					className='flex-center'
					icon={<EllipsisOutlined />}
				/>
			</Tooltip>
		</Dropdown>
	);
};

export default MoreActions;
