import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import { IOrderJourney } from 'types/api';

export const ORDER_JOURNEY_API = {
	list: (params: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/order-journeys',
		method: 'GET',
		params,
	}),
	get: (id: IOrderJourney['id']): THttpRequestConfig => ({
		url: `operations/order-journeys/${id}`,
		method: 'GET',
	}),
};
