import { EStatus } from 'types/common';

// ! enums & types
export enum EPaymentMethods {
	CASH = 'cash',
	CREDIT_CARD = 'credit_card',
	KNET = 'knet',
	WALLET = 'wallet',
	MADA = 'mada',
	APPLE_PAY = 'apple_pay',
	UAE_CC = 'uae_cc',
	BANK_ACCOUNT = 'bank_account',
	PROMOTION = 'promotion',
	PROMOTION_AND_BANK_ACCOUNT = 'promotion_and_bank_account',
	NO_PAYMENT_METHOD = 'no_payment_method',
}

export enum ERefundAllowedPaymentMethods {
	BANK_ACCOUNT = EPaymentMethods.BANK_ACCOUNT,
	PROMOTION = EPaymentMethods.PROMOTION,
	PROMOTION_AND_BANK_ACCOUNT = EPaymentMethods.PROMOTION_AND_BANK_ACCOUNT,
}

// ! interfaces
// * Payments
export interface IPaymentListItemResponse {
	id: number;
	name: EPaymentMethods;
	title: string;
	status: EStatus;

	supports_refund: boolean;
	supports_scheduled_orders: boolean;

	supports_web: boolean;
	supports_ios: boolean;
	supports_android: boolean;
}

export interface IUpdatePaymentData {
	status?: IPaymentListItemResponse['status'];
	supports_scheduled_orders?: IPaymentListItemResponse['supports_scheduled_orders'];
	supports_web?: IPaymentListItemResponse['supports_web'];
	supports_ios?: IPaymentListItemResponse['supports_ios'];
	supports_android?: IPaymentListItemResponse['supports_android'];
}

// * Refund Methods
export interface IRefundMethodItem {
	id: number;
	name: ERefundAllowedPaymentMethods;
	title: string;
}
