import { FC } from 'react';
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from 'configs/common';
import { DisplayNameAndNameProps } from './types';
import { Flex, Space, Typography as T } from 'antd';

export const DisplayNameAndNameAr: FC<DisplayNameAndNameProps> = ({
	name = DEFAULT_EMPTY_VALUE_PLACEHOLDER,
	nameAr = DEFAULT_EMPTY_VALUE_PLACEHOLDER,
}) => {
	const abbrAr = 'AR';
	const abbrEn = 'EN';

	return (
		<Flex
			gap={4}
			vertical
			className='w-100'
		>
			<T.Text ellipsis={{ tooltip: name }}>
				<Space>
					<T.Text type='secondary'>{abbrEn}:</T.Text>
					{name}
				</Space>
			</T.Text>

			<T.Text ellipsis={{ tooltip: nameAr }}>
				<Space>
					<T.Text type='secondary'>{abbrAr}:</T.Text>
					{nameAr}
				</Space>
			</T.Text>
		</Flex>
	);
};
