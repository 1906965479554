const OUTER_PADDING = 32;
const BUTTON_WIDTH = 24;
const MIN_WIDTH = 88;
const GAP = 8;

export const getActionButtonsColumnWidth = (buttonsLength: number, extraWidth?: number) => {
	let finalWidth = OUTER_PADDING + BUTTON_WIDTH * buttonsLength + GAP * (buttonsLength - 1);

	if (extraWidth) {
		finalWidth += extraWidth + GAP;
	}

	return Math.max(MIN_WIDTH, finalWidth);
};
