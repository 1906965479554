import { FC, ReactNode, useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'hooks';
import { IPermissionConfigItem } from 'configs/permissions';
import { ABSOLUTE_ROUTES } from 'configs/routes';

export const ProtectedAccessByPermissionWrapper: FC<{
	children?: ReactNode;
	permissions: IPermissionConfigItem[];
}> = ({ permissions, children = <Outlet /> }) => {
	const { user, hasPermission } = useAuth();

	const isRouteAccessible = useMemo(() => {
		if (!user) return false;

		return hasPermission(permissions);
	}, [hasPermission, permissions, user]);

	if (!user) return null;

	if (isRouteAccessible) {
		return <>{children}</>;
	}

	return <Navigate to={ABSOLUTE_ROUTES[404]} />;
};
