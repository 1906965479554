import { Suspense, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AREA_DRAWER_STYLES } from './configs';
import { IAreaCustomDrawerProps } from './types';
import { BUTTON_COLOR_SETS, CustomButton, EButtonColorTypes } from 'components/CustomButton';
import CustomDrawer, { ICustomDrawerHandle } from 'components/CustomDrawer';
import { Button, Col, Row } from 'antd';
import { CheckCircleOutlined, RollbackOutlined } from '@ant-design/icons';

const AreaCustomDrawer = forwardRef<ICustomDrawerHandle, IAreaCustomDrawerProps>(
	({ title, children, submitLoading, showMainActions = true, onClose, onRevertChanges, onSubmitNewAreas }, ref) => {
		const { t: tAreas } = useTranslation('areas', { keyPrefix: 'areas_map.drawer' });

		// ! render
		if (!ref) return null;

		const drawerFooter = (
			<Row gutter={[8, 16]}>
				{showMainActions && (
					<>
						<Col span={12}>
							<CustomButton
								block
								type='primary'
								icon={<RollbackOutlined />}
								colorTokens={BUTTON_COLOR_SETS[EButtonColorTypes.btn_details]}
								onClick={onRevertChanges}
							>
								{tAreas('actions.revert_changes')}
							</CustomButton>
						</Col>
						<Col span={12}>
							<CustomButton
								block
								type='primary'
								loading={submitLoading}
								icon={<CheckCircleOutlined />}
								colorTokens={BUTTON_COLOR_SETS[EButtonColorTypes.btn_green]}
								onClick={onSubmitNewAreas}
							>
								{tAreas('actions.submit_changes')}
							</CustomButton>
						</Col>
					</>
				)}
				<Col span={24}>
					<Button
						block
						onClick={onClose}
					>
						{tAreas('actions.dismiss_btn')}
					</Button>
				</Col>
			</Row>
		);

		return (
			<CustomDrawer
				ref={ref}
				width={320}
				mask={false}
				title={title}
				footer={drawerFooter}
				horizontalPadding={12}
				styles={AREA_DRAWER_STYLES}
				onClose={onClose}
			>
				<Suspense>{children}</Suspense>
			</CustomDrawer>
		);
	}
);

export default AreaCustomDrawer;
