import { FC, useEffect } from 'react';
import { Nullable } from 'types/common';
import { useMap } from '@vis.gl/react-google-maps';

interface ICenterMapComponentProps {
	newCoordinates?: Nullable<google.maps.LatLngLiteral>;
}

export const CenterMapComponent: FC<ICenterMapComponentProps> = ({ newCoordinates }) => {
	const map = useMap();

	useEffect(() => {
		if (!map || !newCoordinates) return;
		map.setCenter(newCoordinates);
	}, [map, newCoordinates]);

	return <></>;
};
