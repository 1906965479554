import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import {
	IArea,
	IAreaCluster,
	IAreaClusterCreateEditPayload,
	ICreateUpdateAreaPayload,
	IUpdateAreaPolygonPayload,
} from 'types/api';

export const AREA_CLUSTER_API = {
	list: (name?: string, params?: THttpRequestParams): THttpRequestConfig => ({
		url: '/operations/store-clusters',
		method: 'GET',
		params: {
			name,
			...params,
		},
	}),

	get: (id: IAreaCluster['id']): THttpRequestConfig => ({
		url: `/operations/store-clusters/${id}`,
		method: 'GET',
	}),

	create: (data: IAreaClusterCreateEditPayload): THttpRequestConfig => ({
		url: '/operations/store-clusters',
		method: 'POST',
		data,
	}),

	update: (id: IAreaCluster['id'], data: IAreaClusterCreateEditPayload): THttpRequestConfig => ({
		url: `/operations/store-clusters/${id}`,
		method: 'PATCH',
		data,
	}),

	delete: (id: IAreaCluster['id']): THttpRequestConfig => ({
		url: `/operations/store-clusters/${id}`,
		method: 'DELETE',
	}),

	// ! other
	listAssociatedStores: (id: IAreaCluster['id']): THttpRequestConfig => ({
		url: `/operations/store-clusters/${id}/stores`,
		method: 'GET',
	}),
};

export const AREAS_API = {
	list: (name?: IArea['name'], params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/areas',
		method: 'GET',
		params: {
			name,
			...params,
		},
	}),

	listCluster:
		(areaId: IArea['id']) =>
		(name?: string, params?: THttpRequestParams): THttpRequestConfig => ({
			url: `operations/areas/${areaId}/store-clusters`,
			method: 'GET',
			params: {
				name,
				...params,
			},
		}),

	genericList: (name?: IArea['name'], params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/areas/generic',
		method: 'GET',
		params: { name, ...params },
	}),

	getDeliveryAreas: () => ({
		url: '/operations/areas/delivery',
		method: 'GET',
	}),

	get: (id: IArea['id']): THttpRequestConfig => ({
		url: `operations/areas/${id}`,
		method: 'GET',
	}),

	create: (data: ICreateUpdateAreaPayload): THttpRequestConfig => ({
		url: 'operations/areas',
		method: 'POST',
		data,
	}),

	update: (id: IArea['id'], data: IArea): THttpRequestConfig => ({
		url: `operations/areas/${id}`,
		method: 'PATCH',
		data,
	}),

	delete: (id: IArea['id']): THttpRequestConfig => ({
		url: `operations/areas/${id}`,
		method: 'DELETE',
	}),

	updatePolygon: (id: IArea['id'], data: IUpdateAreaPolygonPayload): THttpRequestConfig => ({
		url: `operations/areas/${id}/polygon`,
		method: 'PATCH',
		data,
	}),

	deletePolygon: (id: IArea['id']): THttpRequestConfig => ({
		url: `operations/areas/${id}/polygon`,
		method: 'DELETE',
	}),

	// orders
	ordersSuspend: (id: IArea['id']): THttpRequestConfig => ({
		url: `operations/areas/${id}/suspend-orders`,
		method: 'POST',
	}),

	ordersResume: (id: IArea['id']): THttpRequestConfig => ({
		url: `operations/areas/${id}/resume-orders`,
		method: 'POST',
	}),

	getAreasByCoordinate: (params: google.maps.LatLngLiteral): THttpRequestConfig => ({
		url: 'operations/areas/list-by-coordinate',
		method: 'GET',
		params,
	}),
};
