import { FC, memo } from 'react';
import { Divider } from 'antd';
import { DividerProps } from 'antd/lib/divider';

const CustomDivider: FC<DividerProps> = ({ ...restProps }) => {
	// ! render
	return (
		<Divider
			{...restProps}
			orientation='left'
		/>
	);
};

export default memo(CustomDivider);
