import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { NodeIndexOutlined } from '@ant-design/icons';

const OrderJourneyList = lazy(() => import('pages/OrderJourneys/OrderJourneyList'));
const OrderJourneyDetailsPage = lazy(() => import('pages/OrderJourneys/OrderJourneyDetails'));

export const ORDER_JOURNEYS_ROUTES: TRouteObject = {
	path: 'order-journeys',
	element: <ListParamsRouteWrapper key='order-journeys' />,
	handle: {
		translationKey: 'order_journeys',
		permissions: [APP_PERMISSIONS.order_journey.view],
		icon: <NodeIndexOutlined />,
		defaultListParams: LIST_DEFAULT_PARAMS,
		showOnNav: true,
	},
	children: [
		// list page
		{
			index: true,
			element: <OrderJourneyList />,
		},
		// details page
		{
			path: ':orderJourneyId',
			element: <OrderJourneyDetailsPage />,
			handle: {
				translationKey: 'order_journey_details',
				permissions: [APP_PERMISSIONS.order_journey.view],
				showOnNav: false,
			},
		},
	],
};
