import { FC, ReactNode } from 'react';
import styles from './FormSectionLabels.module.css';

interface IFormSectionLabelsProps {
	label: ReactNode;
	extra: ReactNode;
	isRequired?: boolean;
}

export const FormSectionLabels: FC<IFormSectionLabelsProps> = ({ label, extra, isRequired = false }) => (
	<div>
		<h3 className={`h3 ${isRequired ? styles.required : ''}`}>{label}</h3>
		<span className='body_regular_secondary'>{extra}</span>
	</div>
);
