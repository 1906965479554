import { useEffect, useRef, useState } from 'react';
import { useNewHttpClient } from 'hooks';
import { AREAS_API } from 'configs/api';
import { EStoreDeliveryAreaVehicleType, IArea, IVendorBranchDeliveryArea } from 'types/api';
import { IListResponse, Nullable } from 'types/common';

export interface IDeliveryTimes {
	min: IVendorBranchDeliveryArea['min_order_delivery_time'];
	max: IVendorBranchDeliveryArea['max_order_delivery_time'];
}

export interface IAreaListControllerData extends ReturnType<typeof useAreaListController> {}

export const useAreaListController = () => {
	// ! refs
	const areasMap = useRef<Record<IArea['id'], IArea>>({});

	// ! states
	const [isViewAllAreas, setIsViewAllAreas] = useState(true);

	const [hiddenAreasMap, setHiddenAreasMap] = useState<Record<IArea['id'], boolean>>({});
	const [selectedAreasMap, setSelectedAreasMap] = useState<Record<IArea['id'], boolean>>({});

	const [hoveredAreaId, setHoveredAreaId] = useState<Nullable<IArea['id']>>(null);
	const [infoWindowAreaId, setInfoWindowAreaId] = useState<Nullable<IArea['id']>>(null);

	const [selectedVehicleTypeMap, setSelectedVehicleTypeMap] = useState<
		Record<IArea['id'], EStoreDeliveryAreaVehicleType>
	>({});

	const [selectedDeliveryFeeMap, setSelectedDeliveryFeeMap] = useState<
		Record<IArea['id'], IVendorBranchDeliveryArea['delivery_fee']>
	>({});

	const [selectedDeliveryTimesMap, setSelectedDeliveryTimesMap] = useState<Record<IArea['id'], IDeliveryTimes>>({});

	// ! httpClients
	const getHttpClient = useNewHttpClient<IListResponse<IArea>>();

	// ! handlers
	// * fetch info
	const fetchAllAvailableAreas = () => {
		return getHttpClient.request({
			requestConfig: AREAS_API.getDeliveryAreas(),
			successCallback: ({ data }) => {
				data.forEach((area) => {
					areasMap.current[area.id] = area;
				});
			},
		});
	};

	// * hidden areas
	const toggleHiddenArea = (areaId: IArea['id'], newValue?: boolean) => {
		setHiddenAreasMap((prev) => {
			const newState = { ...prev };

			if (newValue !== undefined) {
				newState[areaId] = newValue;
			} else {
				newState[areaId] = !newState[areaId];
			}

			return newState;
		});
	};

	const unHideAreasOnPointer = (clickCoords: google.maps.LatLng) => {
		setHiddenAreasMap((oldHidden) => {
			const newHidden = { ...oldHidden };

			Object.values(areasMap.current).forEach((area) => {
				if (!area.polygon?.coordinates) {
					return;
				}

				const areaPolygon = new google.maps.Polygon();
				areaPolygon.setPath(area.polygon.coordinates);

				if (google.maps.geometry.poly.containsLocation(clickCoords, areaPolygon)) {
					newHidden[area.id] = false;
				}
			});

			return newHidden;
		});
	};

	const handleHideArea = (areaId: IArea['id']) => {
		setHiddenAreasMap((prev) => {
			const newState = { ...prev };

			newState[areaId] = true;

			return newState;
		});
		setInfoWindowAreaId(null);
	};

	const clearHiddenAreas = () => setHiddenAreasMap({});

	const onViewAllAreasChange = (newValue?: boolean) => {
		clearHiddenAreas();

		if (newValue !== undefined) {
			setIsViewAllAreas(newValue);
		} else {
			setIsViewAllAreas((prev) => !prev);
		}
	};

	// * areas selection
	const selectArea = (id: IArea['id']) => {
		// vehicle types
		setSelectedVehicleTypeMap((prev) => {
			const newState = { ...prev };
			newState[id] = EStoreDeliveryAreaVehicleType.ALL;
			return newState;
		});

		// delivery fees
		setSelectedDeliveryFeeMap((prev) => {
			const newState = { ...prev };
			newState[id] = null;
			return newState;
		});

		// delivery times
		setSelectedDeliveryTimesMap((prev) => {
			const newState = { ...prev };
			newState[id] = { min: null, max: null };
			return newState;
		});

		// areas
		setSelectedAreasMap((prev) => {
			const newState = { ...prev };

			if (newState[id] === undefined) {
				newState[id] = true;
			} else {
				delete newState[id];
			}

			return newState;
		});
	};

	const replaceSelectedAreaList = (selectedRowKeys: Array<IArea['id']>) => {
		const newSelectedAreasMap: Record<IArea['id'], boolean> = {};
		const newSelectedVehicleTypeMap: Record<IArea['id'], EStoreDeliveryAreaVehicleType> = {
			...selectedVehicleTypeMap,
		};
		const newSelectedDeliveryFeeMap: Record<IArea['id'], IVendorBranchDeliveryArea['delivery_fee']> = {
			...selectedDeliveryFeeMap,
		};
		const newSelectedDeliveryTimesMap: Record<IArea['id'], IDeliveryTimes> = {
			...selectedDeliveryTimesMap,
		};

		selectedRowKeys.forEach((areaId) => {
			newSelectedAreasMap[areaId] = true;
			newSelectedVehicleTypeMap[areaId] = newSelectedVehicleTypeMap[areaId] || EStoreDeliveryAreaVehicleType.ALL;
			newSelectedDeliveryFeeMap[areaId] = newSelectedDeliveryFeeMap[areaId] ?? null;
			newSelectedDeliveryTimesMap[areaId] = newSelectedDeliveryTimesMap[areaId] ?? { min: null, max: null };
		});

		setSelectedVehicleTypeMap(newSelectedVehicleTypeMap);
		setSelectedDeliveryFeeMap(newSelectedDeliveryFeeMap);
		setSelectedDeliveryTimesMap(newSelectedDeliveryTimesMap);
		setSelectedAreasMap(newSelectedAreasMap);
	};

	const appendNewSelectedAreas = (selectedRowKeys: Array<IArea['id']>) => {
		const newSelectedAreasMap: Record<IArea['id'], boolean> = { ...selectedAreasMap };
		const newSelectedVehicleTypeMap: Record<IArea['id'], EStoreDeliveryAreaVehicleType> = {
			...selectedVehicleTypeMap,
		};
		const newSelectedDeliveryFeeMap: Record<IArea['id'], IVendorBranchDeliveryArea['delivery_fee']> = {
			...selectedDeliveryFeeMap,
		};
		const newSelectedDeliveryTimesMap: Record<IArea['id'], IDeliveryTimes> = {
			...selectedDeliveryTimesMap,
		};

		selectedRowKeys.forEach((areaId) => {
			newSelectedAreasMap[areaId] = true;
			newSelectedVehicleTypeMap[areaId] = newSelectedVehicleTypeMap[areaId] || EStoreDeliveryAreaVehicleType.ALL;
			newSelectedDeliveryFeeMap[areaId] = newSelectedDeliveryFeeMap[areaId] ?? null;
			newSelectedDeliveryTimesMap[areaId] = newSelectedDeliveryTimesMap[areaId] ?? { min: null, max: null };
		});

		setSelectedVehicleTypeMap(newSelectedVehicleTypeMap);
		setSelectedDeliveryFeeMap(newSelectedDeliveryFeeMap);
		setSelectedDeliveryTimesMap(newSelectedDeliveryTimesMap);
		setSelectedAreasMap(newSelectedAreasMap);
	};

	// * areas vehicle type
	const updateAreaVehicleType = (
		id: IArea['id'],
		type: EStoreDeliveryAreaVehicleType = EStoreDeliveryAreaVehicleType.ALL
	) => {
		setSelectedVehicleTypeMap((prev) => {
			const newState = { ...prev };

			newState[id] = type;

			return newState;
		});
	};

	// ! effects
	useEffect(() => {
		fetchAllAvailableAreas();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! return
	return {
		areas: Object.values(areasMap.current),
		areasMap,

		selectedAreasMap,
		setSelectedAreasMap,
		replaceSelectedAreaList,
		appendNewSelectedAreas,
		selectArea,

		isViewAllAreas,
		setIsViewAllAreas,
		onViewAllAreasChange,

		infoWindowAreaId,
		setInfoWindowAreaId,

		hoveredAreaId,
		setHoveredAreaId,

		hiddenAreasMap,
		clearHiddenAreas,
		toggleHiddenArea,
		unHideAreasOnPointer,
		handleHideArea,

		// extra info
		selectedVehicleTypeMap,
		setSelectedVehicleTypeMap,
		updateAreaVehicleType,

		selectedDeliveryFeeMap,
		setSelectedDeliveryFeeMap,

		selectedDeliveryTimesMap,
		setSelectedDeliveryTimesMap,
	};
};
