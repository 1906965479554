import { IFlow, IRelation, IScreen, IScreenItem } from 'types/api';
import { INodeTrunk, TFlowId } from './types';
import { Edge, Node, Position } from '@xyflow/react';

const getPosition = (xLevel: number, yLevel: number) => {
	return {
		x: 0, // xLevel * FLOW_CARD_X,
		y: 0, // yLevel * FLOW_CARD_Y
	};
};

const addNode = (trunk: INodeTrunk, node: Node<IScreen>) => {
	trunk.nodes?.push({
		...node,
		sourcePosition: Position.Right,
		targetPosition: Position.Left,
	});
};

export const getActionId = (screenId: TFlowId, actionId: TFlowId) => `action(${screenId},${actionId})`;
export const getScreenId = ({
	screenId,
	actionId = '',
	targetId,
}: {
	screenId: TFlowId;
	actionId?: TFlowId;
	targetId: TFlowId;
}) => `${screenId}-${actionId}screen${targetId}`;

const addEdge = (trunk: INodeTrunk, edge: Edge) => {
	trunk.edges.push(edge);
};

const processScreenItem = (trunk: INodeTrunk, parentNode: Node) => (item: IScreenItem, itemIndex: number) => {
	item.id = itemIndex.toString();

	// ? add action data to parent
	const newItems = (parentNode.data.items || []) as any[];
	newItems.push(item);
	parentNode.data.items = newItems;

	// ? iterate over relations
	item.relations?.forEach(processRelation(trunk, parentNode, item));

	return trunk;
};

const processRelation =
	(trunk: INodeTrunk, parentNode: Node, item?: IScreenItem) =>
	(
		rel: IRelation,
		//  Relation
		relIndex: number
	): INodeTrunk => {
		const nodeId = getScreenId({
			screenId: parentNode.id,
			targetId: relIndex,
			actionId: item?.id,
		});

		const level = nodeId.split('-').length - 1;

		const node: Node<IScreen> = {
			id: nodeId,
			type: 'screen',
			position: getPosition(level, item ? +item.id! : relIndex),

			data: {
				title: rel.screen.title,
				items: [],
			},
		};

		// ? Set New Screen Node
		addNode(trunk, node);

		// ? Set Edge
		addEdge(trunk, {
			id: nodeId,
			source: parentNode.id,
			sourceHandle: parentNode.type === 'input' ? undefined : getActionId(parentNode.id, item!.id!),
			target: nodeId,
			label: rel.label,
		});

		// ?  iterate over item actions
		rel.screen.items.forEach(processScreenItem(trunk, node));

		return trunk;
	};

export const processOrderFlow = (data: IFlow): INodeTrunk => {
	const trunk: INodeTrunk = { nodes: [], edges: [] };

	const parentNode: Node<IScreen> = {
		id: '0',
		type: 'input',
		data: {
			title: data.title,
			items: [],
			label: data.title,
		},
		position: getPosition(0, 0),
	};

	trunk.nodes?.push(parentNode);

	data.relations.forEach(processRelation(trunk, parentNode));

	return trunk;
};
