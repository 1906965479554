import { FC, memo } from 'react';
import { NavLink, generatePath } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { IOrderCodeLinkLabelProps } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { Tooltip } from 'antd';

export const OrderCodeLinkLabel: FC<IOrderCodeLinkLabelProps> = memo(({ id, label }) => {
	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! render
	const translatedTooltipText = (
		<TranslatedLabel
			nameSpace='common'
			i18nKey='id_tooltip'
			extra={{ id }}
		/>
	);

	return (
		<Tooltip title={translatedTooltipText}>
			<NavLink to={generatePath(ABSOLUTE_ROUTES.ORDER_DETAILS, { countryIsoTwoCode, orderId: id })}>
				{label}
			</NavLink>
		</Tooltip>
	);
});
