import { FC, memo, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useSelector } from 'store';
import { getSelectedCountryCurrency } from 'store/selectors';
import { getAreaCenter } from '../helper';
import { IDeliveryAreaVehicleTypeMarkerProps } from './types';
import styles from './DeliveryAreaVehicleTypeMarker.module.css';
import { CIRCLE_ICON_COLOR, DELIVERY_AREA_VEHICLE_TYPE_ICONS } from './config';
import { Marker } from '@react-google-maps/api';

const DeliveryAreaVehicleTypeMarker: FC<IDeliveryAreaVehicleTypeMarkerProps> = memo(
	({ isSelected, vehicleType, areaDeliveryFee, areaDeliveryTimes, polygonPath = [], areaCenter, ...props }) => {
		// ! selectors
		const currency = useSelector(getSelectedCountryCurrency);

		// ! memos
		const center = useMemo(() => getAreaCenter(polygonPath, areaCenter), [areaCenter, polygonPath]);

		// ! render
		if (!center || polygonPath.length === 0 || !isSelected) {
			return null;
		}

		const hasDeliveryFee = areaDeliveryFee !== null;
		const hasDeliveryTimes = Object.values(areaDeliveryTimes).every((t) => t !== null);
		const highlighted = hasDeliveryFee || hasDeliveryTimes;
		const componentString = window.btoa(
			renderToString(
				<svg
					width='44'
					height='44'
					viewBox='0 0 48 48'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<circle
						cx='24'
						cy='24'
						r='16'
						fill={CIRCLE_ICON_COLOR}
						strokeWidth='2.5'
						stroke={highlighted ? 'white' : 'none'}
					/>

					<g fill='#EDF4F2'>{vehicleType && DELIVERY_AREA_VEHICLE_TYPE_ICONS[vehicleType]}</g>
				</svg>
			)
		);
		const iconUrl = `data:image/svg+xml;charset=UTF-8;base64,${componentString}`;

		const feeLabelConfig = {
			text: `$ ${areaDeliveryFee} ${currency}`,
			fontSize: '15px',
			color: CIRCLE_ICON_COLOR,
			className: styles.marker_label,
		};
		const markerFeeLabel = hasDeliveryFee ? feeLabelConfig : undefined;

		return (
			<Marker
				icon={{
					url: iconUrl,
					anchor: new google.maps.Point(24, 24),
				}}
				label={markerFeeLabel}
				position={center}
				clickable={false}
				{...props}
			/>
		);
	}
);

export default DeliveryAreaVehicleTypeMarker;
