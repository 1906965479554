import { CSSProperties, PropsWithChildren, forwardRef, useImperativeHandle, useState } from 'react';
import { ICustomDrawerHandle, ICustomDrawerProps } from './types';
import stylesCustomDrawer from './CustomDrawer.module.css';
import { Drawer, DrawerProps } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const CustomDrawer = forwardRef<ICustomDrawerHandle, ICustomDrawerProps & DrawerProps & PropsWithChildren>(
	({ horizontalPadding = 24, onClose, children, styles, ...props }, ref) => {
		// ! states
		const [isOpen, setIsOpen] = useState<boolean>(false);

		// ! ref hook
		useImperativeHandle(
			ref,
			() => {
				const api: ICustomDrawerHandle = {
					isOpen,
					open: () => setIsOpen(true),
					close: () => setIsOpen(false),
					toggle: () => setIsOpen((prev) => !prev),
				};

				return api;
			},
			[isOpen]
		);

		// ! styles
		const headerStyle: CSSProperties = {
			border: 'none',
			paddingTop: 40,
			paddingBottom: 20,
			paddingLeft: horizontalPadding,
			paddingRight: horizontalPadding,
			...styles?.header,
		};

		const bodyStyle: CSSProperties = {
			paddingTop: 0,
			paddingLeft: horizontalPadding,
			paddingRight: horizontalPadding,
			...styles?.body,
		};

		const footerStyle: CSSProperties = {
			border: 'none',
			paddingBottom: 56,
			paddingTop: 0,
			paddingLeft: horizontalPadding,
			paddingRight: horizontalPadding,
			...styles?.footer,
		};

		// ! handlers
		const defaultOnClose = () => setIsOpen(false);

		// ! render
		return (
			<Drawer
				className={stylesCustomDrawer.custom_drawer}
				styles={{
					header: headerStyle,
					body: bodyStyle,
					footer: footerStyle,
				}}
				open={props.open || isOpen}
				closeIcon={<CloseOutlined />}
				onClose={onClose ?? defaultOnClose}
				{...props}
			>
				{children}
			</Drawer>
		);
	}
);

export default CustomDrawer;
