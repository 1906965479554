import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import { IBinCardSegmentListItemResponse, IBinCardSegmentPayload } from 'types/api';

export const BIN_CARD_SEGMENTS_API = {
	list: (name?: IBinCardSegmentListItemResponse['name'], params?: THttpRequestParams): THttpRequestConfig => ({
		url: '/operations/bin-segments',
		method: 'GET',
		params: {
			...params,
			name,
		},
	}),
	create: (data: IBinCardSegmentPayload): THttpRequestConfig => ({
		url: '/operations/bin-segments',
		method: 'POST',
		data,
	}),
	update: (id: number, data: Partial<IBinCardSegmentPayload>): THttpRequestConfig => ({
		url: `/operations/bin-segments/${id}`,
		method: 'PATCH',
		data,
	}),
	details: (id: number): THttpRequestConfig => ({
		url: `/operations/bin-segments/${id}`,
		method: 'GET',
	}),
	delete: (id: number): THttpRequestConfig => ({
		url: `/operations/bin-segments/${id}`,
		method: 'DELETE',
	}),
};
