import { IAreaListControllerData, IDeliveryTimes } from 'hooks';

export type TTime = 'min' | 'max';

export enum EDeliveryTimesOptions {
	FROM_PARENT = 'from_parent',
	SPECIFIC_TIME = 'specific_time',
}

export interface IDeliveryTimeSectionProps {
	areaId: number;
	selectedTime: IDeliveryTimes;
	setSelectedDeliveryTimesMap: IAreaListControllerData['setSelectedDeliveryTimesMap'];
	disabled?: boolean;
}
