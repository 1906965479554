import { FC, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useConfirmModal, useCustomer, useForceRefreshData, useNewHttpClient } from 'hooks';
import { transformICustomerAddressToTableData } from './helpers';
import { CUSTOMER_API, LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { ICustomerAddress, ICustomerAddressPayloadData } from 'types/api';
import { Nullable } from 'types/common';
import { ICustomerAddressListTableData } from './types';
import ActionsMenu, { IActionMenuItem, getActionButtonsColumnWidth } from 'components/ActionsMenu';
import TableWrapper from 'components/Table';
import CustomerAddressModal from './CustomerAddressModal';
import { DEFAULT_CUSTOMER_ADDRESS_LIST_COLUMNS } from './config';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const CustomerAddresses: FC = () => {
	const { customerId, isReadOnlyData } = useCustomer();

	const { hasPermission } = useAuth();
	const { openConfirmModal } = useConfirmModal();
	const { forceRefreshData, refreshingData } = useForceRefreshData();

	const { t: tCustomers } = useTranslation('customers');
	const { t: tCommon } = useTranslation('common');

	const [editingAddress, setEditingAddress] = useState<Nullable<ICustomerAddressListTableData>>(null);

	// ! http clients
	const updateHttpClient = useNewHttpClient();
	const deleteHttpClient = useNewHttpClient();

	// ! memos
	const addressesRequestConfig = useMemo(() => CUSTOMER_API.getCustomersAddresses(customerId), [customerId]);

	// ! handlers
	const onCustomerAddressModalConfirm = (data: ICustomerAddressPayloadData) => {
		if (editingAddress) onEditAddress(data, editingAddress.id);
	};

	const onCustomerAddressModalClose = () => setEditingAddress(null);

	const onEditAddress = (data: ICustomerAddressPayloadData, addressId: number) =>
		updateHttpClient.request({
			requestConfig: CUSTOMER_API.updateCustomersAddresses(customerId, addressId, data),
			successCallback: () => {
				onCustomerAddressModalClose();
				refreshingData();
			},
		});

	const onDeleteAddress = (addressId: number) =>
		deleteHttpClient.request({
			requestConfig: CUSTOMER_API.deleteCustomersAddresses(customerId, addressId),
			successCallback: () => refreshingData(),
		});

	// ! config
	const tableColumns = useMemo<ColumnsType<ICustomerAddressListTableData>>(
		() => {
			const canEdit = hasPermission(APP_PERMISSIONS.customer.address.update);
			const canDelete = hasPermission(APP_PERMISSIONS.customer.address.delete);

			return [
				...DEFAULT_CUSTOMER_ADDRESS_LIST_COLUMNS,
				{
					key: 'actions',
					title: tCustomers('shared.entity.actions'),
					width: getActionButtonsColumnWidth(2),
					render: (_, record) => {
						const actions: IActionMenuItem[] = [
							{
								type: 'button',
								title: tCommon('action_buttons.edit'),
								icon: <EditOutlined />,
								disabled: isReadOnlyData || !canEdit,
								actionCb: () => setEditingAddress(record),
							},
							{
								type: 'button',
								title: tCommon('action_buttons.delete'),
								icon: <DeleteOutlined />,
								disabled: isReadOnlyData || !canDelete,
								actionCb: () =>
									openConfirmModal({
										title: tCustomers('customer_details.tabs.addresses.delete_modal_title', {
											id: record.id,
										}),
										onOk: () => onDeleteAddress(record.id),
									}),
							},
						];
						return <ActionsMenu actions={actions} />;
					},
				},
			];
		},
		[hasPermission] // eslint-disable-line react-hooks/exhaustive-deps
	);

	// ! render
	return (
		<>
			<TableWrapper<ICustomerAddressListTableData, ICustomerAddress>
				disableSearch
				columns={tableColumns}
				refetchData={forceRefreshData}
				requestConfig={addressesRequestConfig}
				defaultParams={{ ...LIST_DEFAULT_PARAMS, customer_id: customerId }}
				transformDataToTableData={transformICustomerAddressToTableData}
			/>

			{/* EDIT CUSTOMER ADDRESS MODAL */}
			<CustomerAddressModal
				isOpen={!!editingAddress}
				editingAddress={editingAddress}
				confirmLoading={updateHttpClient.isLoading}
				onClose={onCustomerAddressModalClose}
				onConfirm={onCustomerAddressModalConfirm}
			/>
		</>
	);
};

export default memo(CustomerAddresses);
