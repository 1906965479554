import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { isNumber } from 'utils/validationUtils/isNumber';
import { AREAS_API } from 'configs/api';
import { IArea, IUpdateAreaPolygonPayload } from 'types/api';
import { Nullable } from 'types/common';
import { useNewHttpClient } from './useHttpClient';
import { IHttpClientData } from './useHttpClient/types';
import { App } from 'antd';

interface IAreaContextData {
	id: IArea['id'];
	data: Nullable<IArea>;
	isLoading: boolean;
	areaInfo: Nullable<IArea>;
	fetchAreaData: () => Promise<void>;
	editArea: (area: IArea) => Promise<void>;

	handleDeletePolygon: () => Promise<void>;
	handleSetPolygon: (payload: IUpdateAreaPolygonPayload) => Promise<void>;

	// * extra
	fetchHttpClient: IHttpClientData<IArea>;
	updateHttpClient: IHttpClientData<IArea>;
	deletePolygonHttpClient: IHttpClientData<void>;
	editPolygonHttpClient: IHttpClientData<IArea>;
}

const Context = createContext<IAreaContextData>({} as IAreaContextData);

const useArea = () => {
	return useContext(Context);
};

const AreaProvider = ({ children = <Outlet /> }: PropsWithChildren) => {
	const { t: tAreas } = useTranslation('areas');
	const { message } = App.useApp();

	const { areaId: idParam } = useParams();
	const areaIdParam = idParam || '';

	// ! http client
	const fetchHttpClient = useNewHttpClient<IArea>();
	const updateHttpClient = useNewHttpClient<IArea>();
	const deletePolygonHttpClient = useNewHttpClient<void>();
	const editPolygonHttpClient = useNewHttpClient<IArea>();

	// ! states
	const [areaData, setAreaData] = useState<Nullable<IArea>>(null);

	// ! handlers
	const fetchAreaData = (id: IArea['id'], signal?: AbortSignal) => {
		return fetchHttpClient.request(
			{
				requestConfig: AREAS_API.get(id),
				successCallback: (data) => {
					// remove first polygon point when is a repetition of last.
					if (
						data.polygon &&
						data.polygon.coordinates.length > 2 &&
						JSON.stringify(data.polygon?.coordinates[0]) ===
							JSON.stringify(data.polygon?.coordinates[data.polygon?.coordinates.length - 1])
					) {
						const [, ...polygonPath] = data.polygon?.coordinates;
						data.polygon.coordinates = polygonPath;
					}

					setAreaData(data);
				},
			},
			signal
		);
	};

	const editArea = (area: IArea) => {
		return updateHttpClient.request({
			requestConfig: AREAS_API.update(+areaIdParam, area),
			successCallback: (data) => {
				setAreaData(data);

				// success message
				const msg = tAreas('messages.update_success', { id: areaIdParam });

				message.success(msg, 3);
			},
		});
	};
	const handleDeletePolygon = () => {
		return deletePolygonHttpClient.request({
			requestConfig: AREAS_API.deletePolygon(+areaIdParam),
			successCallback: () => {
				message.success(tAreas('messages.update_success'), 3);
			},
		});
	};
	const handleSetPolygon = (payload: IUpdateAreaPolygonPayload) => {
		return editPolygonHttpClient.request({
			requestConfig: AREAS_API.updatePolygon(+areaIdParam, payload),
			successCallback: () => message.success(tAreas('messages.update_success'), 3),
		});
	};

	// ! effects
	useEffect(() => {
		if (!areaIdParam || !isNumber(+areaIdParam) || +areaIdParam <= 0) return;

		const ctrl = new AbortController();

		fetchAreaData(+areaIdParam, ctrl.signal);

		return () => ctrl.abort();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// ! render
	if (!areaIdParam) return null;

	const vendorContextData = {
		id: +areaIdParam,
		data: areaData,

		isLoading:
			fetchHttpClient.isLoading ||
			updateHttpClient.isLoading ||
			deletePolygonHttpClient.isLoading ||
			editPolygonHttpClient.isLoading,
		areaInfo: areaData,
		fetchAreaData: () => fetchAreaData(+areaIdParam),

		editArea,
		handleDeletePolygon,
		handleSetPolygon,

		// extra

		fetchHttpClient,
		updateHttpClient,
		deletePolygonHttpClient,
		editPolygonHttpClient,
	};

	return <Context.Provider value={vendorContextData}>{children}</Context.Provider>;
};

export default useArea;
export { AreaProvider, useArea };
