import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import { IBranch } from 'types/api';

export const STORES_API = {
	genericList: (name?: IBranch['info']['name'], params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/stores/generic',
		method: 'GET',
		params: {
			...params,
			name,
		},
	}),
};
