import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { BANNERS_API } from 'configs/api';
import { IBanner } from 'types/api';
import { TEmptyFunction } from 'types/common';
import { useNewHttpClient } from './useHttpClient';

export interface IBannerControllerData extends ReturnType<typeof useBannerController> {}

const useBannerController = () => {
	const { bannerId = '' } = useParams();

	const [data, setData] = useState<IBanner>();

	// ! http clients
	const fetchHttpClient = useNewHttpClient<IBanner>();
	const deleteHttpClient = useNewHttpClient();

	// ! handlers
	const fetchBannerData = () => {
		return fetchHttpClient.request({
			requestConfig: BANNERS_API.getDetails(+bannerId),
			successCallback: (data) => setData(data),
		});
	};

	const handleDelete = (id: number, successCallback: TEmptyFunction) => {
		deleteHttpClient.request({
			requestConfig: BANNERS_API.delete(id),
			successCallback,
		});
	};

	// ! effects
	useEffect(() => {
		fetchBannerData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		bannerId,
		data,
		fetchData: fetchBannerData,
		handleDelete,

		fetchHttpClient,
		deleteHttpClient,
	};
};

const BannerContext = createContext<IBannerControllerData>({} as IBannerControllerData);

const useBanner = () => {
	return useContext(BannerContext);
};

const BannerProvider = ({ children = <Outlet /> }: PropsWithChildren) => {
	const bannerData = useBannerController();

	// ! render
	if (!bannerData.bannerId) return null;

	return <BannerContext.Provider value={bannerData}>{children}</BannerContext.Provider>;
};

export default useBanner;
export { BannerProvider, useBanner };
