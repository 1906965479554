import { createSelector } from '@reduxjs/toolkit';
import {
	reduceCountriesDataToTimezoneOptionsList,
	reduceSelectedCountryDataToCountryCodeOptionsList,
} from 'store/helpers';
import { ICountriesSlice } from 'store/slices/countries';
import { DEFAULT_MAP_CENTER } from 'configs/common';
import { ICountry } from 'types/api';
import { ESupportedCountriesIsoTwoCodes } from 'types/common';
import { ISelector } from '../types';
import { getSettingsSlice } from './settings';

export const getCountrySlice: ISelector<void, ICountriesSlice> = (state) => state.countries;

export const getCountryMap = createSelector([getCountrySlice], ({ countriesList = [] }) => {
	return countriesList.reduce<Record<ICountry['id'] | ICountry['iso_two_code'], ICountry>>((acc, country) => {
		acc[country.id] = country;
		acc[country.iso_two_code] = country;

		return acc;
	}, {});
});

export const getSelectedCountry = createSelector(
	[getCountryMap, getSettingsSlice],
	(countriesMap, { selectedCountryId }) => {
		if (!selectedCountryId) {
			return null;
		}

		return countriesMap[selectedCountryId];
	}
);

export const getMapCenterBySelectedCountry = createSelector(
	[getSelectedCountry],
	(selectedCountry): google.maps.LatLngLiteral => {
		if (!selectedCountry?.center) return DEFAULT_MAP_CENTER;

		return selectedCountry.center;
	}
);

export const getSelectedCountryCurrency = createSelector([getSelectedCountry], (selectedCountryItem) => {
	return selectedCountryItem?.currency_code ?? '';
});

export const getSelectedCountryIsoTwoCode = createSelector([getSelectedCountry], (selectedCountryItem) => {
	return selectedCountryItem?.iso_two_code.toLowerCase() ?? '';
});

export const getCountryCodeOptionsList = createSelector([getSelectedCountry], (selectedCountryItem) => {
	if (!selectedCountryItem) {
		return [];
	}

	return reduceSelectedCountryDataToCountryCodeOptionsList(selectedCountryItem);
});

export const getCountryList = createSelector([getCountrySlice], ({ countriesList }) => countriesList);

export const getAccessibleCountryList = createSelector(
	[getCountryMap, getCountrySlice],
	(countriesMap, { accessibleCountries }) => {
		if (!Object.values(countriesMap).length) return [];

		return accessibleCountries.map((countryId) => countriesMap[countryId]);
	}
);

export const getAccessibleCountryMap = createSelector([getAccessibleCountryList], (accessibleCountryList) => {
	return accessibleCountryList.reduce<Record<ICountry['id'] | ICountry['iso_two_code'], ICountry>>((acc, country) => {
		acc[country.id] = country;
		acc[country.iso_two_code] = country;

		return acc;
	}, {});
});

export const getSelectedCountryTimezones = createSelector([getCountryList], (countriesList) =>
	reduceCountriesDataToTimezoneOptionsList(countriesList)
);

export const isUnitedArabEmiratesCountry = createSelector([getSelectedCountryIsoTwoCode], (isoTwoCode) => {
	return isoTwoCode === ESupportedCountriesIsoTwoCodes.UAE;
});

export const isSaudiArabiaCountry = createSelector([getSelectedCountryIsoTwoCode], (isoTwoCode) => {
	return isoTwoCode === ESupportedCountriesIsoTwoCodes.SAUDI_ARABIA;
});
