import { EOnOffStatus, EVehicleTypes } from 'types/common';
import { EDocumentStatus, IArea, ICustomer, IOrder, IRecordTimestamps, IRequiredDocument } from '.';
import { ISupplier } from './suppliers';
import dayjs from 'dayjs';

// ! enums
export enum EDriverAccountStatus {
	PENDING = 'pending',
	APPROVED = 'approved',
	BLOCKED = 'blocked',
	REJECTED = 'rejected',
}

export enum EDriverCapacityStatus {
	FREE = 'free', // Indicates the driver is online and has no orders.
	PARTIAL_CAPACITY = 'partial_capacity', // Indicates the driver is online and has orders but not at max capacity.
	FULL_CAPACITY = 'full_capacity', // Indicates the driver is online and at max order capacity.
}

export enum EDriverCashLimitStatus {
	WITHIN_LIMIT = 'within_limit',
	REACHED_LIMIT = 'reached_limit',
}

export enum EDriverLogAction {
	ACCOUNT_STATUS_CHANGED = 'account-status-changed',
	GOES_ONLINE = 'goes-online',
	GOES_OFFLINE = 'goes-offline',
}

export enum EDriverLocationStatus {
	VALID_LOCATION = 'valid_location',
	NO_LOCATION = 'no_location',
}

export enum EDriverAssignableOrderTypes {
	ALL = 'all', // can receive any order regardless of payment status or type
	PAID = 'paid', // can only receive paid orders
}

// ! interfaces
export interface IDriverInfo {
	first_name: string;
	last_name: string;
	email: string;
	country_code: string;
	phone_number: string;
	avatar: string;
	rating: number;
	// cash
	cash_limit: number;
	cash_at_hand: number;
	reached_cash_limit: EDriverCashLimitStatus;
	// orders
	max_parallel_orders: number;
	assignable_order_type: EDriverAssignableOrderTypes;
}

export interface IDriverStatusInfo {
	account_status: EDriverAccountStatus;
	capacity_status: EDriverCapacityStatus;
	location_status: EDriverLocationStatus;
	cash_status: EDriverCashLimitStatus;
	current_status: EOnOffStatus;
}

interface IDriverLocationInfo {
	last_location_time: Date | string;
	last_location_coordinates: {
		lat: string;
		lng: string;
	};
}

interface IDriverAreaInfo {
	id: IArea['id'];
	name: IArea['name'];
}

export interface IDriver {
	id: number;
	info: IDriverInfo;
	supplier: ISupplier;
	status: IDriverStatusInfo;
	location?: IDriverLocationInfo;
	areas: IDriverAreaInfo[];
}

export interface IDriverListItemResponse {
	id: IDriver['id'];
	info: {
		first_name: IDriverInfo['first_name'];
		last_name: IDriverInfo['last_name'];
		country_code: IDriverInfo['country_code'];
		phone_number: IDriverInfo['phone_number'];
		email: IDriverInfo['email'];
		rating: IDriverInfo['rating'];
		cash_limit: IDriverInfo['cash_limit'];
		cash_at_hand: IDriverInfo['cash_at_hand'];
		reached_cash_limit: IDriverInfo['reached_cash_limit'];
	};
	status: IDriverStatusInfo;
}

// * Orders
export interface IDriverOrder {
	id: IDriver['id'];
	info: {
		code: IOrder['info']['code'];
		status: IOrder['info']['status'];
	};
	customer: {
		id: ICustomer['id'];
		name: string;
	};
	payment: {
		amount_payable: IOrder['payment']['amount_payable'];
		method: IOrder['payment']['method'];
		paid_at: string;
		currency_code: IOrder['payment']['currency_code'];
	};
}

// * Bank Details
export interface IDriverBankDetails {
	account_number: string;
	holder_name: string;
	bank_name: string;
	bank_location: string;
	payment_email: string;
	bic_swift_code: string;
}

// * Vehicle Details
export interface IDriverVehicleDetails {
	type: EVehicleTypes;
	brand: string;
	model_name: string;
	model_year: number;
	plate_number: string;
}

// * Last Location
export interface IDriverLastLocation {
	driver_id: IDriver['id'];
	lat: string;
	lng: string;
}

// * Documents
export interface IDriverDocumentListItemResponse extends IRecordTimestamps {
	id: number;
	document_file: string;
	status: EDocumentStatus;

	required_document: IRequiredDocument;
}

// * Logs
export interface IDriverLogItem {
	id: number;
	action: EDriverLogAction;
	description: string;
	created_at: string;
}

// ! CRUD -----------
export interface IUpdateDriverPayload {
	email?: IDriver['info']['email'];
	first_name?: IDriver['info']['first_name'];
	last_name?: IDriver['info']['last_name'];
	country_code?: IDriver['info']['country_code'];
	phone_number?: IDriver['info']['phone_number'];
	supplier_id?: ISupplier['id'];
	cash_limit?: IDriver['info']['cash_limit'];
	cash_at_hand?: IDriver['info']['cash_at_hand'];
	area_ids?: IArea['id'][];
	// orders
	max_parallel_orders?: IDriver['info']['max_parallel_orders'];
	assignable_order_type?: IDriver['info']['assignable_order_type'];
}

export interface IUpdateDriverCashInfoPayload extends Pick<IUpdateDriverPayload, 'cash_limit' | 'cash_at_hand'> {}

export interface IUpdateDriverOrdersInfoPayload
	extends Pick<IUpdateDriverPayload, 'max_parallel_orders' | 'assignable_order_type'> {}

// * Bank details
export interface IUpdateDriverBankDetailsFields {
	account_number: string;
	holder_name: string;
	bank_name: string;
	bank_location: string;
	payment_email: string;
	bic_swift_code: string;
}

export interface IUpdateDriverBankDetailsConfigArgs {
	id: number;
	data: IUpdateDriverBankDetailsFields;
}

// * Reset Driver Password
export interface ISetNewDriverPassPayload {
	password: string;
	confirm_password: string;
}

export interface IDriverCitcData {
	identity_type_id: string;
	id_number: string;
	date_of_birth: string | dayjs.Dayjs;
	mobile: string;
	region_id: string;
	car_type_id: string;
	city_id: string;
	car_number: string;
	vehicle_sequence_number: string;
}

export interface IUpdateDriverCitcData {
	identity_type_id: string;
	date_of_birth: string | dayjs.Dayjs;
	mobile: string;
	region_id: string;
	car_type_id: string;
	city_id: string;
	car_number: string;
	vehicle_sequence_number: string;
}
