import { ReactNode } from 'react';
import { IArea } from 'types/api';

export enum EAreasFilterMode {
	ALL = 'all',
	SPECIFIC = 'specific',
}

export interface IAreasSelectionTableData {
	key: React.Key;
	id: IArea['id'];
	name: ReactNode;
	name_ar: ReactNode;
}

export interface ISelectAresName extends Omit<IAreasSelectionTableData, 'id'> {}
