export const DIVIDER_STYLES = {
	margin: '2px 0',
};

export const DEFAULT_POLYGON_OPTIONS = {
	fillOpacity: 0.2,
	fillColor: 'purple',
	//
	strokeOpacity: 1,
	strokeWeight: 2,
	//
	strokeColor: 'purple',
	zIndex: 3,
};
