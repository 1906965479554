import { EChoiceOption, EDeliveryMode, EUserTypes, Nullable } from 'types/common';
import { IProductCorrected } from 'pages/Orders/OrderDetails/RedeliverOrderDrawer/types';
import { IArea } from './areas';
import { IDefaultCategory } from './categories';
import { ICustomer, ICustomerAddress } from './customer';
import { ICustomerSupportRequest } from './customer-support-requests';
import { IOrderIssue } from './dispatcherOrders';
import { IDriver } from './drivers';
import { TIntegrationType } from './integration';
import { EPaymentMethods } from './payments';
import { IOption, IProduct, IVariant } from './products';
import { EPromotionSource } from './promotions';
import { IBranch, IVendor } from './vendors';
import { Dayjs } from 'dayjs';

// ! enums
export enum EOrderType {
	SINGLE = 'single',
	GROUP = 'group',
	CORRECTION = 'correction',
}

export enum EOrderStatus {
	ROOT = 0,
	SCHEDULED = 90,
	NEW = 100,
	PREPARING = 120,
	READY = 130,
	DISPATCHED = 140,
	// Sequential, hardly likely to change
	COMPLETED = 150,
	REJECTED = 151,
	CANCELLED = 152,
	FAILED = 153,
	AWAITING_PAYMENT = 154,
	INTERVENTION = 155,
	ABANDONED = 156, // Unused
}

export enum EOrderDeliveryStatus {
	// ! preparing
	DRIVER_NOT_ASSIGNED = 100, // PENDING, old value: 0
	DRIVER_IS_ASSIGNED = 110, // old value: 1
	DRIVER_HEADING_TO_STORE = 120, // old value: 11
	DRIVER_ARRIVED_AT_STORE = 130, // old value: 3
	// ! ready
	ORDER_PICKED_UP = 140, // old value: 104
	// ! dispatched
	DRIVER_ON_ROUTE_TO_CUSTOMER = 150, // ORDER_PICKED_UP_AND_ON_ROUTE = 5, // old value: 5  ---> tracking starts
	DRIVER_ARRIVED_AT_CUSTOMER = 160, // old value: 105
	DRIVER_DELIVERED_THE_ORDER = 170, // old value: 6
	DRIVER_COLLECTED_THE_PAYMENT = 180, // old value: 7 // ! To be dropped
	// ! others
	COMPLETED = 190, // old value: 9
	CANCELLED = 191, // old value: 4
	FAILED = 192, // generic failures, old value: 10
	DISPUTED = 193, // dispute/"court-like" situation, // old value: 12
}

export enum EOrderDisputedStatus {
	DISPUTED = 'disputed',
	NOT_DISPUTED = 'not_disputed',
}

export enum EOrderPaymentStatus {
	NOT_PAID = 'not_paid',
	PAID = 'paid',
}

export enum EOrderPaymentMethods {
	CASH = EPaymentMethods.CASH,
	CREDIT_CARD = EPaymentMethods.CREDIT_CARD,
	KNET = EPaymentMethods.KNET,
	WALLET = EPaymentMethods.WALLET,
	MADA = EPaymentMethods.MADA,
	APPLE_PAY = EPaymentMethods.APPLE_PAY,
	UAE_CC = EPaymentMethods.UAE_CC,
	BANK_ACCOUNT = EPaymentMethods.BANK_ACCOUNT,
	PROMOTION = EPaymentMethods.PROMOTION,
	NO_PAYMENT_METHOD = EPaymentMethods.NO_PAYMENT_METHOD,
}

export enum EOrderBookingTypes {
	INSTANT = 'instant',
	SCHEDULED = 'scheduled',
}

export enum EOrderWalletComplementAmountRate {
	NONE = 'none',
	PARTIAL = 'partial',
	FULL = 'full',
}

export enum ECorrectionReason {
	MISSING_ITEM = 'missing_item',
	WRONG_ITEM = 'wrong_item',
	DAMAGED_ITEM = 'damaged_item',
}

// ! interfaces
export interface IOrderCustomer {
	id: ICustomer['id'];
	name: string;
	contact_number: string;
}

export interface IOrderBranch {
	vendor_id: IVendor['id'];
	vendor_name: IVendor['info']['name'];

	id: IBranch['id'];
	name: IBranch['info']['name'];
	lat: IBranch['location']['lat'];
	lng: IBranch['location']['lng'];
	address: IBranch['location']['address'];
	timezone: IBranch['location']['timezone'];

	min_order_delivery_time: IBranch['delivery']['min_order_delivery_time'];
	max_order_delivery_time: IBranch['delivery']['max_order_delivery_time'];

	area: {
		id: IArea['id'];
		name?: IArea['name'];
	};
}

export interface IOrderInfo {
	code: string;
	booking_type?: EOrderBookingTypes;
	pickup_time?: string;
	type: EOrderType;
	status: EOrderStatus;
	status_description: string;
	delivery_status: EOrderDeliveryStatus;
	delivery_mode: EDeliveryMode;
	delivery_mode_description: string;
	delivery_mode_description_detailed: string;
	dispute_status: EOrderDisputedStatus;
	dispute_status_description: string;
	additional_info?: string;
	placed_at: string;
	invoice_url: string;
	min_customer_delivery_eta_at: string;
	max_customer_delivery_eta_at: string;
	integration_type: TIntegrationType;
}

export interface IOrderDriver {
	id: IDriver['id'];
	name: string;
}

export interface IOrderDeliveryAddress {
	address: string;
	lat: string;
	lng: string;
}

export interface IOrderPayment {
	total_item_cost: number;
	store_discount_amount: number;
	platform_discount_amount: number;
	expected_delivery_cost: number;
	delivery_cost: number;
	status: EOrderPaymentStatus;
	method: EOrderPaymentMethods;
	currency_code: string;
	paid_at: Nullable<string>;
	iso_currency_code?: string;
	tax_description?: string;

	grand_total: number;
	amount_payable: number;
	wallet_complement_amount: number;
	wallet_complement_amount_rate: EOrderWalletComplementAmountRate;
}

interface IProductSelectedOptions {
	id: number;
	name: IOption['name'];
	unit_price: IOption['price'];
	quantity: number;
}

interface IProductSelectedVariant {
	id: number;
	name: IVariant['name'];
	options?: IProductSelectedOptions[];
	barcode?: string;
	sku?: string;
}

export interface IOrderProductDetails {
	id: IProduct['id'];
	name: IProduct['name'];

	special_request?: string;

	quantity: number;
	unit_price: IProduct['price'];

	variant: IProductSelectedVariant;
	sub_category_name: IDefaultCategory['name'];
	category_name: IDefaultCategory['name'];

	correction_info?: ICorrectionInfo;
}

export interface IOrderParticipant {
	id: number;
	nickname: string;
	is_host: EChoiceOption;
	products: IOrderProductDetails[];
}

interface IOrderPromotionsUsed {
	platform: string;
	vendor: string;
	promotion_ids: string;
}

export interface ICorrectionInfo {
	correction_reason: ECorrectionReason;
	correction_explanation: string;
}

export interface ICorrectionGroup {
	child_order_id: IOrder['id'];
	child_order_code: IOrder['info']['code'];
}

export interface ICorrectionBatch {
	//used when order as correction type
	parent_order_id?: IOrder['id'];
	parent_order_code?: IOrder['info']['code'];
	//used when order has been corrected
	child_order_id?: IOrder['id'];
	child_order_code?: IOrder['info']['code'];
}

export interface IOrder {
	id: number;
	info: IOrderInfo;
	customer: IOrderCustomer;
	store: IOrderBranch;
	promotions_used: IOrderPromotionsUsed;
	payment: IOrderPayment;
	driver?: IOrderDriver;
	delivery_address: IOrderDeliveryAddress;
	delivery_area: {
		id: IArea['id'];
		name?: IArea['name'];
		min_order_delivery_time?: IBranch['delivery']['min_order_delivery_time'];
		max_order_delivery_time?: IBranch['delivery']['max_order_delivery_time'];
	};
	products?: IOrderProductDetails[];
	participants?: IOrderParticipant[];
	actions: IOrderActions;
	issue: IOrderIssue;
	correction_info?: ICorrectionBatch;
	action_descriptions: IOperationsOrderActionDescriptions;
}

export interface IOrderListItemResponse {
	id: IOrder['id'];
	info: {
		code: IOrderInfo['code'];
		type: IOrderInfo['type'];
		placed_at: IOrderInfo['placed_at'];
		status: IOrderInfo['status'];
		dispute_status: IOrderInfo['dispute_status'];
		integration_type: IOrderInfo['integration_type'];
	};
	customer: {
		id: IOrderCustomer['id'];
		name: IOrderCustomer['name'];
	};
	store: {
		id: IOrderBranch['id'];
		name: IOrderBranch['name'];
		vendor_id: IOrderBranch['vendor_id'];
		vendor_name: IOrderBranch['vendor_name'];
	};
	payment: {
		grand_total: IOrderPayment['grand_total'];
		currency_code: IOrderPayment['currency_code'];
	};
	driver?: {
		id: IOrderDriver['id'];
		name: IOrderDriver['name'];
	};
}

interface IDriverOrderLocation {
	lat: string;
	lng: string;
}

export interface IOrderLog {
	id: number;

	status: string;
	delivery_status: string;
	payment_status: string;
	dispute_status: EOrderDisputedStatus;

	user_id: number;
	user_name: string;
	user_role?: EUserTypes;

	reason?: string;
	comment?: string;
	created_at: string;

	driver_location?: IDriverOrderLocation;
}

// ! crud
export interface IUpdateOrdersStatusConfig {
	status: number;
	comment?: string;
}

export interface IUpdateGlobalStatusPayload {
	status: EOrderStatus;
	delivery_status: EOrderDeliveryStatus;
	payment_status: EOrderPaymentStatus;
	comment: string;
}

export interface IOrderActions {
	can_transition_to_new: boolean;
	can_prepare: boolean;
	can_mark_as_ready: boolean;
	can_assign_driver: boolean;
	can_unassign_driver: boolean;
	can_complete: boolean;
	can_cancel: boolean;
	can_reject: boolean;
	can_generate_invoice: boolean;
	can_download_invoice: boolean;
	can_clear_dispute: boolean;
	can_redeliver_correction_order: boolean;
	can_correct: boolean; // less than 4 hours or already corrected
	can_show_correction_button: boolean;
	can_retry_delivery: boolean;
}

export interface IOperationsOrderActionDescriptions {
	can_transition_to_new?: string;
	can_prepare?: string;
	can_mark_as_ready?: string;
	can_assign_driver?: string;
	can_unassign_driver?: string;
	can_complete?: string;
	can_cancel?: string;
	can_reject?: string;
	can_generate_invoice?: string;
	can_download_invoice?: string;
	can_clear_dispute?: string;
	can_correct?: string;
}

export interface ICreateCorrectedOrderPayload {
	order_id: IOrder['id'];
	charge_to: EPromotionSource;
	customer_address_id: ICustomerAddress['id'];
	products: IProductCorrected[];
}

interface MyFatoorahValidationError {
	Name: string;
	Error: string;
}

export interface IMyFatoorahGetPaymentStatusResponse {
	IsSuccess: boolean;
	isExpired: boolean;
	Message: string;
	ValidationErrors: MyFatoorahValidationError[];
	Data: any; // MyFatoorahGetPaymentStatusDataResponse
}

export interface IOrderCustomerSupportRequestListItemResponse {
	id: ICustomerSupportRequest['id'];
	info: {
		request_date: ICustomerSupportRequest['info']['request_date'];
		request_type_description: ICustomerSupportRequest['info']['request_type_description'];
		requested_payment_method: ICustomerSupportRequest['info']['requested_payment_method'];
		resolved_date?: Dayjs;
		type: ICustomerSupportRequest['info']['type'];
		status: ICustomerSupportRequest['info']['status'];
	};
}
