import { FC, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, generatePath, useMatch, useNavigate } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import useArea from 'hooks/useArea';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { EAreaTabsKeys } from './types';
import { CustomTabs } from 'components/CustomTabs';
import PageContainer from 'components/PageContainer';
import ErrorPage from 'pages/ErrorPage';
import { AREA_TABS_ROUTES } from './routes/routes.area-tabs';
import { PageHeader } from '@ant-design/pro-layout';

const AreaDetailsLayout: FC = () => {
	const { id, fetchHttpClient } = useArea();
	const navigate = useNavigate();

	const { t: tAreas } = useTranslation('areas');

	const match = useMatch(ABSOLUTE_ROUTES.MANAGEMENT_AREA_DETAILS_TABS + '/*');

	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! handlers
	const onTabChange = (activeKey: EAreaTabsKeys) => {
		navigate(
			generatePath(ABSOLUTE_ROUTES.MANAGEMENT_AREA_DETAILS_TABS, {
				countryIsoTwoCode,
				areaId: id,
				areaTab: activeKey,
			})
		);
	};

	// ! render
	if (fetchHttpClient.error) {
		return (
			<ErrorPage
				status={fetchHttpClient.error.status}
				title={fetchHttpClient.error.status}
				subTitle={fetchHttpClient.error.data?.message}
			/>
		);
	}

	return (
		<PageContainer
			pageHeader={
				<>
					<PageHeader
						title={tAreas('details.title')}
						subTitle={`#${id}`}
						onBack={() => navigate('..')}
					/>

					<CustomTabs
						routes={AREA_TABS_ROUTES}
						activeKey={match?.params.areaTab}
						onTabChange={onTabChange}
					/>
				</>
			}
			withInnerPadding
		>
			<Suspense>
				<Outlet />
			</Suspense>
		</PageContainer>
	);
};

export default AreaDetailsLayout;
