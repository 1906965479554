import { IListResponse } from 'types/common';
import { IArea, IAreaCluster } from './areas';
import { ICustomer } from './customer';
import { IDriver } from './drivers';
import { IOrder } from './orders';

// ! enums & types
export enum EOrderJourneyStatus {
	CANCELLED = 'cancelled',
	COMPLETED = 'completed',
	IN_PROGRESS = 'in_progress',
	NOT_STARTED = 'not_started',
	PENDING = 'pending',
}

// ! interfaces
export interface IOrderJourneyAreaInfo {
	id: IArea['id'];
	name: IArea['name'];
}

interface IOrderJourneyCluster {
	id: IAreaCluster['id'];
	name: IAreaCluster['name'];
	area?: {
		id: IOrderJourneyAreaInfo['id'];
	};
}

export interface IOrderJourneyOrder {
	id: IOrder['id'];
	code: IOrder['info']['code'];
	status: IOrder['info']['status'];
	customer: {
		id: ICustomer['id'];
		name: string;
	};
	delivery: {
		origin_area: IOrderJourneyAreaInfo;
		delivery_area: IOrderJourneyAreaInfo;
	};
}

export interface IOrderJourney {
	id: number;
	driver: {
		id: IDriver['id'];
		name: string;
	};
	created_at: string;
	status: EOrderJourneyStatus;
	orders: IListResponse<IOrderJourneyOrder>;
	origin_cluster?: IOrderJourneyCluster;
	delivery_area: IOrderJourneyAreaInfo;
}

export interface IOrderJourneyListItemResponse {
	id: IOrderJourney['id'];
	driver: IOrderJourney['driver'];
	created_at: IOrderJourney['created_at'];
	status: IOrderJourney['status'];
	orders: IOrderJourney['orders'];
}
