import { FC, useMemo } from 'react';
import { IAreaPolygonProps } from './types';
import { Polygon } from '@react-google-maps/api';

const AreaPolygon: FC<IAreaPolygonProps> = ({
	area,
	clickable,
	isHovered,
	isIntersected,
	isSelected,
	isVisible,
	onClick,
	onMouseOver,
	onRightClick,
	onDblClick,
	...restProps
}) => {
	// ! memos
	const colorOptions = useMemo(() => {
		let fillColor = 'lightsteelblue';

		let strokeColor = fillColor;
		let zIndex = 1;

		if (isSelected) {
			fillColor = 'green';
			strokeColor = 'green';

			zIndex = 3;
		}

		if (isHovered) {
			fillColor = 'grey';
			strokeColor = 'grey';

			zIndex = 4;
		}

		if (isSelected && isHovered) {
			fillColor = 'green';
			strokeColor = 'greenyellow';
		}

		if (isIntersected) {
			fillColor = 'orange';
			strokeColor = 'orange';

			zIndex = 5;
		}

		return { fillColor, strokeColor, zIndex };
	}, [isHovered, isIntersected, isSelected]);

	// ! render
	return (
		<Polygon
			key={`polygon-${area.id}`}
			visible={isVisible}
			path={area.polygon?.coordinates}
			options={{ ...colorOptions, clickable }}
			onClick={onClick}
			onDblClick={onDblClick}
			onRightClick={onRightClick}
			onMouseOver={onMouseOver}
			{...restProps}
		/>
	);
};

export default AreaPolygon;
