import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNewHttpClient } from 'hooks';
import useArea from 'hooks/useArea';
import { isNumber } from 'utils/validationUtils/isNumber';
import { AREA_CLUSTER_API } from 'configs/api';
import { IAreaClusterCreateEditPayload } from 'types/api';
import FormSectionLabels from 'components/FormSectionLabels';
import PageContainer from 'components/PageContainer';
import Spinner from 'components/Spinner';
import { useAreaCluster } from './useAreaCluster';
import { App, Button, Col, Flex, Form, Input, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { PageHeader } from '@ant-design/pro-layout';

const AreaClusterCreateEdit: FC = () => {
	const { message } = App.useApp();
	const { t: tAreas } = useTranslation('areas');
	const { t: tCommon } = useTranslation('common');
	const navigate = useNavigate();
	const { id } = useArea();
	const { id: clusterId, data, fetchHttpClient } = useAreaCluster();

	const [form] = useForm<IAreaClusterCreateEditPayload>();

	const isEdit = !!clusterId && isNumber(clusterId);

	// ! http clients
	const saveHttpClient = useNewHttpClient();

	// ! handlers
	const handleGoBack = () => {
		navigate('..');
	};

	const handleFormFinish = (values: IAreaClusterCreateEditPayload) => {
		values.area_id = id;

		let requestConfig = AREA_CLUSTER_API.create(values);

		if (clusterId && isEdit) {
			requestConfig = AREA_CLUSTER_API.update(clusterId, values);
		}

		return saveHttpClient.request({
			requestConfig,
			successCallback: () => {
				if (isEdit) {
					message.success(tAreas('messages.cluster.update.success'), 3);
				} else {
					message.success(tAreas('messages.cluster.create.success'), 3);
				}
				handleGoBack();
			},
		});
	};

	// ! effects

	useEffect(() => {
		if (data) {
			form.setFieldsValue(data);
		}
	}, [data, form]);

	// ! render
	return (
		<PageContainer
			pageHeader={
				<PageHeader
					title={isEdit ? tAreas('edit_area_cluster.title') : tAreas('add_area_cluster.title')}
					subTitle={isEdit && `#${clusterId}`}
					onBack={handleGoBack}
				/>
			}
			withInnerPadding
		>
			{fetchHttpClient.isLoading && <Spinner defaultAntdSpinner />}

			<Form<IAreaClusterCreateEditPayload>
				form={form}
				layout='vertical'
				onFinish={handleFormFinish}
			>
				<Flex
					vertical
					gap={12}
				>
					<>
						<FormSectionLabels
							label={tAreas('form.names.label')}
							extra={tAreas('form.names.extra')}
						/>

						<Row gutter={16}>
							<Col span={12}>
								<Form.Item
									name='name'
									label={tAreas('form.languages.english')}
									initialValue=''
									rules={[{ required: true }, { type: 'string', max: 100 }, { whitespace: true }]}
								>
									<Input placeholder={tAreas('form.name.placeholder')} />
								</Form.Item>
							</Col>

							<Col span={12}>
								<Form.Item
									name='name_ar'
									label={tAreas('form.languages.arabic')}
									initialValue=''
									rules={[{ required: true }, { type: 'string', max: 100 }, { whitespace: true }]}
								>
									<Input placeholder={tAreas('form.name_ar.placeholder')} />
								</Form.Item>
							</Col>
						</Row>
					</>
					<>
						<FormSectionLabels
							label={tAreas('form.description.label')}
							extra={tAreas('form.description.extra')}
						/>

						<Row gutter={16}>
							<Col span={24}>
								<Form.Item
									name='description'
									initialValue=''
									rules={[{ type: 'string', max: 100 }, { whitespace: true }]}
								>
									<Input.TextArea placeholder={tAreas('form.description.placeholder')} />
								</Form.Item>
							</Col>
						</Row>
					</>

					{/* Actions Buttons */}
					<Flex
						gap={12}
						justify='center'
					>
						<Button
							type='default'
							onClick={handleGoBack}
						>
							{tCommon('action_buttons.cancel')}
						</Button>
						<Button
							type='primary'
							htmlType='submit'
						>
							{tCommon('action_buttons.save')}
						</Button>
					</Flex>
				</Flex>
			</Form>
		</PageContainer>
	);
};

export default AreaClusterCreateEdit;
