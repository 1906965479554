import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import {
	IChangeAssociatedVendorsPayload,
	IManageBinCardSegmentPayload,
	IPlanCreatePayload,
	IPlanUpdatePayload,
	IVendor,
} from 'types/api';

export const CARI_UNLIMITED_API = {
	get: (): THttpRequestConfig => ({
		url: 'operations/cari-unlimited',
		method: 'GET',
	}),
	// ! PLANS
	getPlanList: (params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/cari-unlimited/plans',
		method: 'GET',
		...params,
	}),
	createPlan: (data: IPlanCreatePayload): THttpRequestConfig => ({
		url: 'operations/cari-unlimited/plans',
		method: 'POST',
		data,
	}),
	getPlanDetails: (planId: number): THttpRequestConfig => ({
		url: `operations/cari-unlimited/plans/${planId}`,
		method: 'GET',
	}),
	updatePlan: (planId: number, data: IPlanUpdatePayload): THttpRequestConfig => ({
		url: `operations/cari-unlimited/plans/${planId}`,
		method: 'PATCH',
		data,
	}),
	managePlanBinSegments: (planId: number, data: IManageBinCardSegmentPayload): THttpRequestConfig => ({
		url: `operations/cari-unlimited/plans/${planId}/bin-segments`,
		method: 'POST',
		data,
	}),
	// ! Manage Associated Vendors
	getAssociatedVendors: (): THttpRequestConfig => ({
		url: '/operations/cari-unlimited/associated-vendors',
		method: 'GET',
	}),

	getAssociableVendors: (name?: IVendor['info']['name'], params?: THttpRequestParams): THttpRequestConfig => ({
		url: '/operations/cari-unlimited/associable-vendors',
		method: 'GET',
		params: { ...params, name },
	}),

	addVendors: (data: IChangeAssociatedVendorsPayload): THttpRequestConfig => ({
		url: '/operations/cari-unlimited/associate-vendors',
		method: 'POST',
		data,
	}),

	removeVendors: (data: IChangeAssociatedVendorsPayload): THttpRequestConfig => ({
		url: '/operations/cari-unlimited/remove-vendors',
		method: 'POST',
		data,
	}),
};
