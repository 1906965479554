import { ICustomer } from './customer';
import { IDriver } from './drivers';
import { IOrder } from './orders';
import { IBranch, IVendor } from './vendors';

export enum ERatingStatus {
	DISABLED = 'disabled',
	ENABLED = 'enabled',
}

export interface IRating {
	id: number;
	rating: number;
	status: ERatingStatus;
	comment: string;
	order: {
		id: IOrder['id'];
		code: IOrder['info']['code'];
	};
	customer: {
		id: ICustomer['id'];
		name: string;
	};
}

export interface IBranchRatingListItemResponse extends IRating {
	store: {
		id: IBranch['id'];
		name: IBranch['info']['name'];
		vendor_id: IVendor['id'];
	};
}

export interface IDriverRatingListItemResponse extends IRating {
	driver: {
		id: IDriver['id'];
		name: string;
	};
}
