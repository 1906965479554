import { FC, useEffect } from 'react';
import { useSelector } from 'store';
import { getMapCenterBySelectedCountry } from 'store/selectors';
import { IVisCommonMapProps } from './types';
import { Map, MapProps, useMap } from '@vis.gl/react-google-maps';

// TODO : TEMPORARY HOLD OF MAP IDs
export const MAP_ID: Record<'POI' | 'NO_POI', string> = {
	POI: 'd6f73bb126266b2b',
	NO_POI: '5dbe8468eff8f3f4',
};

const VisCommonMap: FC<IVisCommonMapProps> = ({ mapId, children, defaultCenter, defaultZoom, onLoad, ...mapProps }) => {
	// ! selectors
	const mapCenterByCountry = useSelector(getMapCenterBySelectedCountry);

	const mapStylesOptions: MapProps = {
		clickableIcons: false,
		minZoom: 8, //zoom out
	};

	// ! render
	const OnMapLoad = () => {
		const map = useMap();

		useEffect(() => {
			if (map && onLoad) {
				onLoad(map);
			}
		});

		return <></>;
	};

	return (
		<Map
			mapId={mapId}
			defaultZoom={defaultZoom ?? undefined}
			defaultCenter={defaultCenter ?? mapCenterByCountry}
			{...mapProps}
			{...mapStylesOptions}
		>
			<OnMapLoad />

			{children}
		</Map>
	);
};

export default VisCommonMap;
