import { useTranslation } from 'react-i18next';
import { useConfirmModal, useNewHttpClient } from 'hooks';
import { VERTICALS_API } from 'configs/api';
import { STATUS_NEW_VALUE_CONFIG } from 'configs/common';
import { EVerticalType, IVertical } from 'types/api';
import { EStatus, TEmptyFunction } from 'types/common';
import { App } from 'antd';

export const useVerticalController = () => {
	const { openConfirmModal } = useConfirmModal();
	const { message } = App.useApp();

	const { t: tCommon } = useTranslation('common');
	const { t: tVerticals } = useTranslation('verticals');

	// ! http clients
	const updateRequestHttp = useNewHttpClient();

	// ! handlers
	const handleToggleVerticalStatus = (
		verticalId: number,
		status: EStatus,
		successCb: TEmptyFunction
	): Promise<void> =>
		updateRequestHttp.request({
			requestConfig: VERTICALS_API.update(verticalId, { status }),
			successCallback: () => {
				message.success(tVerticals('feedback.vertical.toggle_state.success'));
				successCb();
			},
		});

	// * status
	const onToggleVerticalStatusConfirmModal = ({ id, status, name, type }: IVertical, successCb: TEmptyFunction) => {
		const statusActionLabel: string = tCommon(
			status === EStatus.ACTIVE ? 'action_buttons.deactivate' : 'action_buttons.activate'
		).toLocaleLowerCase();
		const newStatus = STATUS_NEW_VALUE_CONFIG[status];

		const showContent = status === EStatus.ACTIVE && type !== EVerticalType.PREMIUM;

		openConfirmModal({
			title: tVerticals('toggle_vertical_status_modal.title', { status: statusActionLabel, id }),
			content: showContent ? tCommon('disable_entity_affect_banners', { id, name }) : undefined,
			onOk: () => handleToggleVerticalStatus(id, newStatus, successCb),
		});
	};

	return { onToggleVerticalStatusConfirmModal };
};
