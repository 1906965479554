import { IAreaListControllerData } from 'hooks';
import { IVendorBranchDeliveryArea } from 'types/api';

export enum EDeliveryFeeOptions {
	FROM_PARENT = 'from_parent',
	SPECIFIC_FEE = 'specific_fee',
}

export interface IDeliveryFeeSectionProps {
	areaId: number;
	selectedFee: IVendorBranchDeliveryArea['delivery_fee'];
	setSelectedDeliveryFeeMap: IAreaListControllerData['setSelectedDeliveryFeeMap'];
	disabled?: boolean;
}
