import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import { ECustomerSegmentFilterBy, ICreateUpdateCustomerSegmentPayload, ICustomer, ICustomerSegment } from 'types/api';
import { EStatus } from 'types/common';

export const CUSTOMER_SEGMENT_API = {
	list: (search?: string, filterType?: ECustomerSegmentFilterBy, params?: THttpRequestParams): THttpRequestConfig => {
		const finalParams: THttpRequestConfig['params'] = {
			...params,
		};

		if (filterType) {
			finalParams[filterType] = search;
		}

		return {
			url: 'operations/customer-segments',
			method: 'GET',
			params: finalParams,
		};
	},

	create: (data: ICreateUpdateCustomerSegmentPayload): THttpRequestConfig => ({
		url: `operations/customer-segments`,
		method: 'POST',
		data,
	}),

	get: (id: ICustomerSegment['id']): THttpRequestConfig => ({
		url: `operations/customer-segments/${id}`,
		method: 'GET',
	}),

	delete: (id: ICustomerSegment['id']): THttpRequestConfig => ({
		url: `operations/customer-segments/${id}`,
		method: 'DELETE',
	}),

	getCustomerList: (id: ICustomerSegment['id']): THttpRequestConfig => ({
		url: `operations/customer-segments/${id}/customer-list`,
		method: 'GET',
	}),

	addCustomerList: (id: ICustomerSegment['id'], list: Array<ICustomer['id']>): THttpRequestConfig => ({
		url: `operations/customer-segments/${id}/add-customer`,
		method: 'POST',
		data: { id: list },
	}),

	removeCustomer: (id: ICustomerSegment['id'], customerId: ICustomer['id']): THttpRequestConfig => ({
		url: `operations/customer-segments/${id}/remove-customer`,
		method: 'POST',
		data: { id: [customerId] },
	}),

	update: (id: ICustomerSegment['id'], data: ICreateUpdateCustomerSegmentPayload): THttpRequestConfig => ({
		url: `operations/customer-segments/${id}`,
		method: 'PATCH',
		data,
	}),

	toggleState: (id: ICustomerSegment['id'], newState: EStatus): THttpRequestConfig => ({
		url: `operations/customer-segments/${id}/status`,
		method: 'PATCH',
		data: { status: newState },
	}),
};
