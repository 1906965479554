import { JSX } from 'react';
import { Property } from 'csstype';
import { EHttpStatus } from 'types/api';
import { EOnOffStatus } from 'types/common';

export interface IBranchesLocationData {
	id: number;
	vendor_id: number;
	name: string;
	location: google.maps.LatLng | google.maps.LatLngLiteral;
}

export interface IBranchesLocationResponse {
	count: number;
	data: IBranchesLocationData[];
}

// ! enums
export enum ELiveTrackingDriverCapacityStatus {
	FREE = 10,
	PARTIAL_CAPACITY = 11,
	FULL_CAPACITY = 12,
}

// ! enums
export enum ELiveTrackingDeliveryStatus {
	ASSIGNED = 20,
	HEADING_TO_STORE = 30,
	ARRIVED_AT_STORE = 40,
	PICKED_UP_ORDER = 50,
	HEADING_TO_CUSTOMER = 60,
	ARRIVED_AT_CUSTOMER = 70,
	DISPUTED = 80,
}

export enum ELiveTrackingDriverVehicle {
	CAR = 20,
	MOTORCYCLE = 30,
}

// ! Interfaces
// * DRIVER
export interface IILiveTrackingDriverOrder {
	id: number;
	code: string;
	customer_name: string;
	delivery_address: string;
	delivery_status: ELiveTrackingDeliveryStatus;
}

export interface IUnSerializedLiveTrackingDriver {
	id: number;
	name: string;
	phone_number: string;
	supplier_name?: string;
	location: google.maps.LatLngLiteral;
	capacity_status: ELiveTrackingDriverCapacityStatus;
	delivery_status: ELiveTrackingDeliveryStatus;
	vehicle: ELiveTrackingDriverVehicle;
	isFresh: boolean;

	// orders info
	orders?: IILiveTrackingDriverOrder[];
	max_parallel_orders: number;
	active_order_count: number;
	cash_at_hand: number;
	cash_limit: number;
}

export interface ILiveTrackingDriver extends IUnSerializedLiveTrackingDriver {
	color: Property.Color;
	ordersColors: Property.Color[];
}

export type TLiveTrackingDriversPerVehicle = Record<ELiveTrackingDriverVehicle, IUnSerializedLiveTrackingDriver[]>;
export type TLiveTrackingDriversData = Record<
	ELiveTrackingDriverCapacityStatus | ELiveTrackingDeliveryStatus,
	TLiveTrackingDriversPerVehicle
>;

// * DRIVER METRICS
export type ILiveTrackingMetricItem<T extends number> = Record<T, number>;

export interface ILiveTrackingMetrics {
	online: number;
	offline: number;
	capacity_status: ILiveTrackingMetricItem<ELiveTrackingDriverCapacityStatus>;
	delivery_status: ILiveTrackingMetricItem<ELiveTrackingDeliveryStatus>;
	vehicle: ILiveTrackingMetricItem<ELiveTrackingDriverVehicle>;
}

// * FILTERS SETTINGS
export interface ILiveTrackingFilterConfigItem<T> {
	value: T;
	label: string;
	filter: boolean;
	color: Property.Color;
	icon: JSX.Element;
}

export interface ILiveTrackingAvailabilityFilterConfigItem<T> {
	value: T;
	label: string;
	color?: string;
}

export type TLiveTrackingStatusToColor = Record<
	ELiveTrackingDriverCapacityStatus | ELiveTrackingDeliveryStatus,
	Property.Color
>;

export interface ILiveTrackingSettings {
	availability: ILiveTrackingAvailabilityFilterConfigItem<EOnOffStatus>[];
	capacity_status: ILiveTrackingFilterConfigItem<ELiveTrackingDriverCapacityStatus>[];
	delivery_status: ILiveTrackingFilterConfigItem<ELiveTrackingDeliveryStatus>[];
	vehicle: ILiveTrackingFilterConfigItem<ELiveTrackingDriverVehicle>[];
	status_color: TLiveTrackingStatusToColor;
}

// * EVENTS
// SETTINGS
type SettingsOmit = 'capacity_status' | 'delivery_status' | 'vehicle';
interface UnSerializedLiveTrackingFiltersSettings extends Omit<ILiveTrackingSettings, SettingsOmit> {
	capacity_status: Omit<ILiveTrackingFilterConfigItem<ELiveTrackingDriverCapacityStatus>, 'icon'>[];
	delivery_status: Omit<ILiveTrackingFilterConfigItem<ELiveTrackingDeliveryStatus>, 'icon'>[];
	vehicle: Omit<ILiveTrackingFilterConfigItem<ELiveTrackingDriverVehicle>, 'icon'>[];
}

export interface ILiveTrackingSettingsSuccess extends UnSerializedLiveTrackingFiltersSettings {}

export type ILiveTrackingSettingsEvent = ILiveTrackingSettingsSuccess | ISocketEventErrorResponse;

// AUTH_CHECK
export interface ILiveTrackingAuthSuccess {
	status: EHttpStatus;
}

// DRIVERS_ONLINE
export interface ILiveTrackingDriversOnline {
	drivers: TLiveTrackingDriversData;
	metrics: ILiveTrackingMetrics;
}

// NATIVE SOCKET ERROR EVENT RESPONSE
export interface ISocketEventErrorResponse {
	status: number;
	error: string;
}
