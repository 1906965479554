import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from 'configs/common';
import { IAreasMapHeaderProps } from './types';
import styles from './AreasMapHeader.module.css';
import { Alert, Button, Flex, Typography as T, Tooltip } from 'antd';
import { InfoCircleOutlined, ToolOutlined } from '@ant-design/icons';

const AreasMapHeader: FC<IAreasMapHeaderProps> = ({
	loading,
	isEditingMap,
	areasCount,
	toolsButtonDisabled,
	onToolsButtonClick,
}) => {
	const { t: tAreas } = useTranslation('areas', { keyPrefix: 'areas_map.header' });

	// ! render
	return (
		<Flex
			gap='small'
			align='center'
			justify='space-between'
			className={styles.header_container}
		>
			<Flex
				gap='middle'
				align='center'
				justify='space-between'
			>
				<T.Text strong>{`${tAreas('selected_areas')}: ${
					loading ? DEFAULT_EMPTY_VALUE_PLACEHOLDER : areasCount
				}`}</T.Text>

				{isEditingMap && (
					<Tooltip
						title={
							<Flex vertical>
								{['right_click', 'left_click'].map((action) => (
									<Flex
										gap='small'
										key={action}
									>
										<span>{tAreas(`edit_help_info.${action}.label`)}: </span>
										<span>{tAreas(`edit_help_info.${action}.info`)}</span>
									</Flex>
								))}
							</Flex>
						}
					>
						<T.Text type='secondary'>
							<Flex gap={4}>
								<InfoCircleOutlined />
								{tAreas('edit_help_info.label')}
							</Flex>
						</T.Text>
					</Tooltip>
				)}
			</Flex>

			{isEditingMap && (
				<Alert
					showIcon
					type='warning'
					className={styles.alert}
					message={tAreas('edit_mode_alert')}
				/>
			)}

			<Button
				icon={<ToolOutlined />}
				disabled={toolsButtonDisabled}
				onClick={onToolsButtonClick}
			>
				{tAreas('edit_tools_button')}
			</Button>
		</Flex>
	);
};

export default memo(AreasMapHeader);
