import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'store';
import { getMapCenterBySelectedCountry } from 'store/selectors';
import { DEFAULT_USER_INTERACTION_DEBOUNCE } from 'utils/debounce';
import { MAP_LIBRARIES } from 'configs/common';
import MapSearchBox from 'components/MapSearchBox';
import { MAP_CONTAINER_STYLE } from 'components/PolygonMap/config';
import { IPolygonMapHandle, IPolygonMapProps } from 'components/PolygonMap/types';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';

const PolygonMap = forwardRef<IPolygonMapHandle, IPolygonMapProps>(
	(
		{
			googleMapsApiKey,
			children,
			mapCenter,
			centerRadius,
			mapContainerStyle = {},
			zoom = 14, // INITIAL ZOOM
			...props
		},
		ref
	) => {
		const searchBoxRef = useRef<google.maps.places.SearchBox>();
		const [searchLocation, setSearchLocation] = useState<google.maps.LatLng>();
		const [googleMapInstance, setGoogleMapInstance] = useState<google.maps.Map>();

		useImperativeHandle(
			ref,
			() => {
				const api: IPolygonMapHandle = {
					getMapInstance: () => googleMapInstance,
				};

				return api;
			},
			[googleMapInstance]
		);

		// * map js api
		const { isLoaded: isMapLoaded, loadError } = useLoadScript({
			id: 'tts-polygon-map',
			googleMapsApiKey,
			libraries: MAP_LIBRARIES,
		});

		// ! selectors
		const mapCenterByCountry = useSelector(getMapCenterBySelectedCountry);

		// !handlers
		const onSearchBoxLoad = useCallback((ref: google.maps.places.SearchBox) => (searchBoxRef.current = ref), []);

		const onSearchBoUnmount = useCallback(() => (searchBoxRef.current = undefined), []);

		const onSearchPlacesChanged = useCallback(() => {
			const places = searchBoxRef?.current?.getPlaces();

			if (!places?.length) return;
			// otherwise, set the map center to the new searched location
			setSearchLocation(places[0].geometry?.location ?? undefined);
		}, []);

		const applyFitBoundsRadius = (map?: google.maps.Map) => {
			if (centerRadius && map) {
				const circle: google.maps.Circle = new google.maps.Circle({
					center: mapCenter ?? mapCenterByCountry,
					radius: centerRadius * 1000,
				});
				map.fitBounds(circle.getBounds()!);
			}
		};

		const onMapLoad = (map: google.maps.Map) => setGoogleMapInstance(map);

		// ! useEffects
		useEffect(() => {
			// * Note: 'setTimeout' is set only for animation purposes.
			setTimeout(() => applyFitBoundsRadius(googleMapInstance), DEFAULT_USER_INTERACTION_DEBOUNCE);
		}, [centerRadius, googleMapInstance]); // eslint-disable-line react-hooks/exhaustive-deps

		// ! return
		return (
			<>
				{isMapLoaded && !loadError && (
					<GoogleMap
						zoom={zoom}
						mapContainerStyle={{ ...MAP_CONTAINER_STYLE, ...mapContainerStyle }}
						center={searchLocation || (mapCenter ?? mapCenterByCountry)}
						onLoad={onMapLoad}
						{...props}
					>
						<MapSearchBox
							onLoad={onSearchBoxLoad}
							onUnmount={onSearchBoUnmount}
							onPlacesChanged={onSearchPlacesChanged}
						/>
						{children}
					</GoogleMap>
				)}
			</>
		);
	}
);

export default PolygonMap;
