import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNewHttpClient } from 'hooks';
import { isNumber } from 'utils/validationUtils/isNumber';
import { AREA_CLUSTER_API } from 'configs/api';
import { IAreaCluster } from 'types/api';
import { Nullable } from 'types/common';

export const useAreaCluster = () => {
	const { clusterId: idParam } = useParams();
	const clusterIdParam = idParam || '';

	// ! http clients
	const fetchHttpClient = useNewHttpClient<IAreaCluster>();

	// ! states
	const [data, setData] = useState<Nullable<IAreaCluster>>(null);

	// ! handlers
	const fetchData = (id: IAreaCluster['id'], signal?: AbortSignal) => {
		return fetchHttpClient.request(
			{
				requestConfig: AREA_CLUSTER_API.get(id),
				successCallback: (data) => {
					setData(data);
				},
			},
			signal
		);
	};

	// ! effects
	useEffect(() => {
		if (!clusterIdParam || !isNumber(+clusterIdParam) || +clusterIdParam <= 0) return;

		const ctrl = new AbortController();

		fetchData(+clusterIdParam, ctrl.signal);

		return () => ctrl.abort();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// ! render

	return {
		id: +clusterIdParam,
		data,

		fetchHttpClient,
	};
};
