import { THttpRequestConfig } from 'hooks';
import { ICustomerSupportFlow, IUpdateFlowItemsStatusPayload } from 'types/api';
import { EStatus } from 'types/common';

export const CUSTOMER_SUPPORT_FLOW_API = {
	list: (): THttpRequestConfig => ({ url: 'operations/customer-support/help-options', method: 'GET' }),

	getFlowDetails: (id: ICustomerSupportFlow['id']): THttpRequestConfig => ({
		url: `operations/customer-support/help-options/${id}`,
		method: 'GET',
	}),

	changeStatus: (id: ICustomerSupportFlow['id'], newStatus: EStatus): THttpRequestConfig => ({
		url: `operations/customer-support/help-options/${id}`,
		method: 'PATCH',
		data: { status: newStatus },
	}),

	changeFlowItemsStatus: (
		id: ICustomerSupportFlow['id'],
		data: IUpdateFlowItemsStatusPayload
	): THttpRequestConfig => ({
		url: `operations/customer-support/help-options/${id}/status`,
		method: 'PATCH',
		data,
	}),
};
