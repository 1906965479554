import { lazy } from 'react';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { EAreaTabsKeys } from '../types';
import TranslatedLabel from 'components/TranslatedLabel';
import AreaClusterCreateEdit from '../AreaClusterCreateEdit/AreaClusterCreateEdit';
import { FullscreenExitOutlined, InfoOutlined } from '@ant-design/icons';

// ! Lazy module imports
const AreaDetails = lazy(() => import('../AreaDetails'));
const AreaClusterList = lazy(() => import('../AreaClusterList'));

export const AREA_DETAILS_TABS_ROUTES: Record<EAreaTabsKeys, TRouteObject> = {
	[EAreaTabsKeys.DETAILS]: {
		path: EAreaTabsKeys.DETAILS,
		element: <AreaDetails />,
		handle: {
			key: EAreaTabsKeys.DETAILS,
			icon: <InfoOutlined />,
			label: (
				<TranslatedLabel
					nameSpace='areas'
					i18nKey={`tabs.${EAreaTabsKeys.DETAILS}.title`}
				/>
			),
		},
	},
	[EAreaTabsKeys.CLUSTERS]: {
		path: EAreaTabsKeys.CLUSTERS,
		handle: {
			key: EAreaTabsKeys.CLUSTERS,
			icon: <FullscreenExitOutlined />,
			label: (
				<TranslatedLabel
					nameSpace='areas'
					i18nKey={`tabs.${EAreaTabsKeys.CLUSTERS}.title`}
				/>
			),
		},
		children: [
			{
				index: true,
				element: <AreaClusterList />,
			},
			{
				path: 'new',
				element: <AreaClusterCreateEdit />,
				handle: {
					permissions: [APP_PERMISSIONS.store_cluster.add],
					showOnNav: false,
				},
			},
			{
				path: ':clusterId',
				element: <AreaClusterCreateEdit />,
				handle: {
					permissions: [APP_PERMISSIONS.store_cluster.update],
					showOnNav: false,
				},
			},
		],
	},
};

export const AREA_TABS_ROUTES: TRouteObject[] = Object.values(AREA_DETAILS_TABS_ROUTES);
