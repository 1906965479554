import { THttpRequestConfig } from 'hooks';
import { ICompany, INewCompanyPayload } from 'types/api';

export const COMPANY_API = {
	list: (name?: ICompany['name']): THttpRequestConfig => ({
		url: 'operations/companies',
		method: 'GET',
		params: {
			name,
		},
	}),
	create: (data: INewCompanyPayload): THttpRequestConfig => ({
		url: 'operations/companies',
		method: 'POST',
		data,
	}),
	update: (id: number, data: Partial<INewCompanyPayload>): THttpRequestConfig => ({
		url: `operations/companies/${id}`,
		method: 'PATCH',
		data,
	}),
};

export default COMPANY_API;
