import { EBranchPreparationMode, EBranchScheduledOrderSupport } from 'types/api';
import { ISelectOption } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';

export const PREPARATION_TIME_LIMIT = {
	min: 1,
	max: 240,
};

export const DEFAULT_INPUT_NUMBER_PROPS = {
	min: 0,
	step: '.1',
	type: 'number',
};

export const DELIVERY_TIME_OPTIONS_LIST: ISelectOption[] = [
	5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34,
	35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 65, 70, 75,
	80, 85, 90, 120, 150, 180, 210, 240, 270, 300,
].reduce<ISelectOption[]>((acc, timeOpt) => {
	acc.push({
		value: timeOpt,
		label: `${timeOpt} Min`,
	});
	return acc;
}, []);

export const SCHEDULED_ORDER_SUPPORT_OPTION_LIST: ISelectOption[] = Object.values(EBranchScheduledOrderSupport).map(
	(value) => ({
		value,
		label: (
			<TranslatedLabel
				nameSpace='vendors'
				i18nKey={value}
				extra={{
					keyPrefix: 'vendor_details.tabs.branches.scheduled_order_support',
				}}
			/>
		),
	})
);

export const PREPARATION_MODE_OPTION_LIST: ISelectOption[] = Object.values(EBranchPreparationMode).map((value) => ({
	value,
	label: (
		<TranslatedLabel
			nameSpace='vendors'
			i18nKey={value}
			extra={{
				keyPrefix: 'vendor_details.tabs.branches.preparation_mode',
			}}
		/>
	),
}));
