import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { FeatureVariantWrapper } from 'hooks/FeatureVariantControl/FeatureVariantWrapper';
import { CustomerSegmentProvider } from 'hooks/useCustomerSegment';
import { PromotionProvider } from './hooks/usePromotion';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { EPromotionType } from 'types/api';
import { EPromotionRoutePaths } from './types';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { CARI_PRIZE_CAMPAIGN_ROUTES } from 'pages/CariPrizeCampaign/routes.cari-prize-campaign';
import { OFFERS_LABELS_ROUTES } from 'pages/OffersLabels/routes.offers-labels';
import { EPromotionDetailsTabsKeys } from './View/Tabs/types';
import { ApartmentOutlined, InfoOutlined } from '@ant-design/icons';
import { NotificationOutlined } from '@ant-design/icons';

// ! Lazy module imports
const DetailsTab = lazy(() => import('pages/Promotions/View/Tabs/DetailsTab'));
const AffectedVendorsTab = lazy(() => import('pages/Promotions/View/Tabs/AffectedVendorsTab'));
const PromotionCreate = lazy(() => import('./Create/PromotionCreate'));
const PromotionList = lazy(() => import('./List/PromotionList'));
const PromotionPageLayout = lazy(() => import('./View/PromotionPageLayout'));

const CustomerSegmentDetails = lazy(
	() => import('pages/CampaignCustomerSegments/CustomerSegmentDetails/CustomerSegmentDetails')
);
const CustomerSegmentsList = lazy(
	() => import('pages/CampaignCustomerSegments/CustomerSegmentsList/CustomerSegmentsList')
);

const getPromotionRoutesByType = (promotionType: EPromotionType): TRouteObject[] => [
	{
		index: true,
		element: <PromotionList promotionType={promotionType} />,
	},
	{
		path: 'new',
		element: <PromotionCreate promotionType={promotionType} />,
		handle: {
			permissions: [APP_PERMISSIONS.promotion.add],
		},
	},
	{
		path: ':promotionId',
		element: (
			<PromotionProvider promotionType={promotionType}>
				<PromotionPageLayout promotionType={promotionType} />
			</PromotionProvider>
		),
		children: [
			{
				index: true,
				element: (
					<Navigate
						to={EPromotionDetailsTabsKeys.DETAILS}
						replace
					/>
				),
			},
			{
				path: 'edit',
				element: <PromotionCreate promotionType={promotionType} />,
				handle: {
					permissions: [APP_PERMISSIONS.promotion.add],
				},
			},
			{
				path: EPromotionDetailsTabsKeys.DETAILS,
				handle: {
					key: EPromotionDetailsTabsKeys.DETAILS,
					icon: <InfoOutlined />,
					permissions: [],
				},
				element: <DetailsTab />,
			},
			{
				path: EPromotionDetailsTabsKeys.VENDORS_AND_BRANCHES,
				handle: {
					key: EPromotionDetailsTabsKeys.VENDORS_AND_BRANCHES,
					icon: <ApartmentOutlined />,
					permissions: [],
				},
				element: <AffectedVendorsTab />,
			},
		],
	},
];

export const PROMOTIONS_ROUTES: TRouteObject = {
	path: 'promotions',
	handle: {
		translationKey: 'promotion_management',
		icon: <NotificationOutlined />,
		permissions: [APP_PERMISSIONS.promotion.view],
		showOnNav: true,
		isNavigateable: false,
	},
	children: [
		{
			path: EPromotionRoutePaths.VENDOR_CAMPAIGNS,
			element: <ListParamsRouteWrapper key='vendor-campaigns' />,
			handle: {
				translationKey: 'campaign_vendor_management',
				permissions: [APP_PERMISSIONS.promotion.view],
				showOnNav: true,
			},
			children: getPromotionRoutesByType(EPromotionType.CAMPAIGN),
		},
		{
			path: EPromotionRoutePaths.RECOMMENDATIONS,
			element: <ListParamsRouteWrapper key='recommendations' />,
			handle: {
				translationKey: 'campaign_recommendations',
				permissions: [],
				showOnNav: true,
			},
			children: getPromotionRoutesByType(EPromotionType.RECOMMENDATION),
		},
		{
			path: EPromotionRoutePaths.CARI_CAMPAIGNS,
			element: <ListParamsRouteWrapper key='cari_campaigns' />,
			handle: {
				translationKey: 'cari_campaigns',
				permissions: [],
				showOnNav: true,
			},
			children: getPromotionRoutesByType(EPromotionType.CODE),
		},
		{
			path: 'cari-prize-campaigns',
			element: (
				<ListParamsRouteWrapper key='cari-prize-campaigns'>
					<FeatureVariantWrapper />
				</ListParamsRouteWrapper>
			),
			handle: {
				translationKey: 'cari_prize_campaigns',
				permissions: [APP_PERMISSIONS.cari_prize_campaign.view],
				showOnNav: true,
			},
			children: CARI_PRIZE_CAMPAIGN_ROUTES,
		},
		{
			path: 'customer-segments',
			element: <ListParamsRouteWrapper key='customer-segments' />,
			handle: {
				translationKey: 'customer_segments',
				showOnNav: true,
				mustHaveABs: { CORE_6378_CAMPAIGNS_CUSTOMER_SEGMENT: 'ACTIVE' },
				permissions: [APP_PERMISSIONS.customer_segment.view],
			},
			children: [
				{
					index: true,
					element: <CustomerSegmentsList />,
				},
				{
					path: ':customerSegmentId',
					element: (
						<CustomerSegmentProvider>
							<CustomerSegmentDetails />
						</CustomerSegmentProvider>
					),
				},
			],
		},

		OFFERS_LABELS_ROUTES,
	],
};
