import { IPermissionItem, IRoleItem, IUserProfile } from 'types/api';

// ! Admins
export interface IUserLogin {
	access_token: string;
	refresh_token: string;
	user: IUserProfile;
}

// ! Operation Users
export enum EOperationsUserType {
	SUPER_ADMIN = 'super_admin',
	ADMIN = 'admin',
	REGULAR = 'regular',
}

export interface IOperationUser {
	id: number;
	name: string;
	email: string;
	user_type: EOperationsUserType;
	roles?: IRoleItem[];
}

export interface ICreateOperationUserForm {
	name: IOperationUser['name'];
	email: IOperationUser['email'];
	password: string;
}

export interface ICreateOperationUserData extends ICreateOperationUserForm {
	role_ids: IRoleItem['id'][];
	permission_ids: IPermissionItem['id'][];
}

export interface IUpdateOperationUserDetailsData extends ICreateOperationUserData {}

export interface IUpdateOperationUserRolesPermissionsData {
	role_ids: ICreateOperationUserData['role_ids'];
	permission_ids: ICreateOperationUserData['permission_ids'];
}
