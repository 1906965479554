import { Suspense, lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { BannerProvider } from 'hooks/useBanner';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { EBannerType } from 'types/api';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { AddEditBannerProvider } from './AddEditBannerProvider';
import { FlagOutlined } from '@ant-design/icons';

const BannerListPageLayout = lazy(() => import('pages/Banners/BannerListPageLayout'));
const BannerDetailsPage = lazy(() => import('pages/Banners/BannerDetailsPage'));

export const BANNERS_ROUTES: TRouteObject = {
	path: 'banners',
	element: (
		<AddEditBannerProvider>
			<ListParamsRouteWrapper key='banners'>
				<Suspense>
					<Outlet />
				</Suspense>
			</ListParamsRouteWrapper>
		</AddEditBannerProvider>
	),
	handle: {
		showOnNav: true,
		icon: <FlagOutlined />,
		translationKey: 'banners',
		permissions: [APP_PERMISSIONS.banners.view],
	},
	children: [
		{
			index: true,
			element: (
				<Navigate
					to={EBannerType.HIGHLIGHTED_HOME}
					replace
				/>
			),
		},
		// list page
		{
			path: ':bannerType',
			children: [
				{
					index: true,
					element: <BannerListPageLayout />,
				},
			],
		},
		// details page
		{
			path: ':bannerId',
			element: <BannerProvider />,
			children: [
				{
					index: true,
					element: (
						<Navigate
							to='details'
							replace
						/>
					),
				},
				{
					path: 'details',
					element: <BannerDetailsPage />,
				},
			],
		},
	],
};
