import { useEffect } from 'react';
import { PropsWithChildren } from 'react';
import { CONFIGURATIONS_API } from 'configs/api';
import { IGoogleMapsConfig } from 'types/api';
import { useNewHttpClient } from './useHttpClient';
import { APIProvider } from '@vis.gl/react-google-maps';

export const useGoogleMapsApiKey = () => {
	const fetchHttpClient = useNewHttpClient<IGoogleMapsConfig>();

	// ! handlers
	const handleFetchGoogleMapsApiKey = () => {
		fetchHttpClient.request({
			requestConfig: CONFIGURATIONS_API.getMapConfig(),
		});
	};

	// ! useEffect
	useEffect(() => {
		handleFetchGoogleMapsApiKey();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		mapApiKey: fetchHttpClient.response?.apiKey,
		mapApiKeyLoading: fetchHttpClient.isLoading,
	};
};

export const GoogleMapsProvider = ({ children }: PropsWithChildren) => {
	const { mapApiKey } = useGoogleMapsApiKey();

	if (!mapApiKey) return null;

	return (
		<APIProvider
			apiKey={mapApiKey}
			libraries={['places', 'geometry']}
		>
			{children}
		</APIProvider>
	);
};
