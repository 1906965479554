export const AREA_DRAWER_STYLES = {
	body: {
		overflow: 'hidden',
		paddingBottom: 16,
	},
	footer: {
		padding: '12px 12px 24px',
		borderTop: '2px solid var(--grey-scale-3)',
	},
};
