import { EStatus } from 'types/common';
import { IBaseTableData } from 'components/Table';

export enum ECustomerSegmentFilterBy {
	NAME = 'name',
	ID = 'id',
}

export interface ICustomerSegment {
	id: number;
	name: string;
	name_ar: string;
	status: EStatus;
	user_quantity: number;

	actions: {
		can_delete: boolean;
	};
}

export interface ICustomerSegmentTableData extends ICustomerSegment, IBaseTableData {}

export interface ICreateUpdateCustomerSegmentPayload {
	name: ICustomerSegment['name'];
	name_ar: ICustomerSegment['name_ar'];
	status: ICustomerSegment['status'];
	user_quantity: ICustomerSegment['user_quantity'];
}

export interface ISelectedCustomerSegment {
	id: ICustomerSegment['id'];
	name: ICustomerSegment['name'];
	name_ar: ICustomerSegment['name_ar'];
}
